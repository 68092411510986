/**
 * MeR 240
 */
import {Fetch} from '../Api'


const onUpdate = (e, selected, Message) => {
    const object = e?.target
    if(object && object.contentEditable) {
        const keycode = (e.keyCode ? e.keyCode : e.which)
        //alert(`update ${keycode} - ${e.shiftKey}`)
        if (keycode === 13) {
            if (e.shiftKey) {
    
            }
            else {
                //alert(`update ${selected.field} ${selected.id}...`)
                
                    if (selected?.id > 0) {
                        object.innerText = object.innerText.slice(0, -2)
                        const data = { ...selected, action:'Update', value: object.innerText}
                        //console.log('data', data); alert(data.value)      
                        Fetch(`data`, data, object, 'PUT') // object for the update style !
                        .then(res=>{
                            const history = {...data, Entity:'HistoryField'}

                            Fetch(`data`, history, object)    // from Historizing(component, element, field, value, entityId)     
                            
                            Message && Message(`${data.value} updated !`)
                        })
                    }
                
                onReadOnly(object)   
            }
            e.stopPropagation()
        }
        else if (keycode === 27) {
            object.value = object.defaultValue
            onReadOnly(object)
        }		
    }
}

const onCell = (e, clicked, Clicked) => {
    const object = e?.target
    if(object) {
        let cr = object.getBoundingClientRect()
        if(cr && cr.right>0 && cr.bottom>0) {
            let w=parseInt(cr.right-cr.left)
            let h=parseInt(cr.bottom-cr.top)
        
    //let message= `onClick (event x ${event.clientX}, y ${event.clientY})`; console.log(message, cr, clicked)//; Now is debuging 
            let n = clicked.n
            let x = parseInt((e.clientX-cr.left)*100/w)||0
            let y = parseInt((e.clientY-cr.top)*100/h)||0
            let now = new Date()
    
            if(Math.abs(clicked.x-x)<3 && Math.abs(clicked.y-y)<3) {
                let ms=now.getTime()-clicked.now.getTime()
                if(ms<500) {
                    n=n+1
    
                    if(n>0) {  // 2 or 3 click !

                        onReadOnly(object, false)
                    }
                }
                else
                    n=0
            }
            else
                n=0
                
            Clicked && Clicked({n,x,y,now})  
        }
    }
}

const onReadOnly = (object, readOnly=true) => {
    
    object.contentEditable = readOnly?'false':'true'
    if(readOnly) {
        object.style.cursor = 'pointer'
        object.style.border = '0px solid blue'
        object.style.backgroundColor = 'transparent'
        object.style.fontWeight = 'normal'
    }
    else {
        
        object.style.cursor = 'text'
        object.style.border = '1px solid blue'
        object.style.backgroundColor = 'rgba(220, 220, 0, 0.5)'
        object.style.fontWeight = 'bold'
        object.focus()
    }
    //console.log(object); alert(`readOnly ${object.readOnly}`)
}


export {onUpdate, onReadOnly, onCell} 