import React,{useEffect, useState} from 'react'
import {Button, Col} from 'react-bootstrap'
import ReadModal from './ReadModal'
import './Reading.css'

const ReadControl = ({path,part,page, onPrev,onNext, onPart, onPage, modal=false}) => {
    const [expanded, Expand] = useState(false)
    const [play, Play] = useState(false)
    const [tilaawa, Tilaawa] = useState("")
    const [wajh, Wajh] = useState("")

    let istyle={display: 'flex', justifyContent: 'center'}
    let tstyle = { position:'absolute', width: '10%', height: '5%', fontWeight:'bold', color:'darkred'}

    var audio = document.getElementById("tilaawa");

    useEffect(()=>{
        let safha=path+(page||0).toString().padStart(3,'0')+'.jpg'
        //console.log('ReadControl', page, safha); //alert(safha)

        Wajh(safha)
        Tilaawa(safha.replace(".jpg",".mp3"))

        onPage && onPage(page)

        if(play && onPlay) {
            onPlay()
        }

    },[page, wajh])

    const onPlay = () => {
        if(!play) {
            audio.play()
            audio.style.visibility='visible'
        }
        else {
            audio.pause()
            audio.style.visibility='hidden'
        }
        Play(!play)
    }
    //audio.stopAll();


    return (
        <div className="page-center">
        {
            wajh.length>0 
        ?
        <>

        <img className="page-fit page-fit-center" id="safha" alt="القرآن الكريم" 
        src={wajh} 
        />
        
        
        <Button className="mr-2" variant="outline-warning" 
        style={{ position:'absolute', width: '5%', height: '10%', top:'45%', left:'1%' }}
            onClick={onNext}
            ><i className="fa fa-arrow-left" style={{display: 'flex', justifyContent: 'center'}}></i></Button>

        <Button className="mr-2" variant="outline-warning" 
        style={{ position:'absolute', width: '5%', height: '10%', top:'45%', left:'94%' }}
            onClick={onPrev}
            ><i className="fa fa-arrow-right" style={{display: 'flex', justifyContent: 'center'}}></i></Button>

<audio id="tilaawa" controls="controls" autobuffer="autobuffer" 
style={{position:'absolute',width:'90%', height:'5%', bottom:0, left:'5%', visibility:'hidden', opacity:'0.7'}} 
src={tilaawa} />

<Button className="mr-2" variant={play?"outline-danger":"outline-success"}  
        style={{ position:'absolute', width: '10%', height: '5%', bottom:0, left:'45%', opacity:'0.7' }}
            onClick={onPlay}
            ><i className={play?"fa fa-pause":"fa fa-play"} style={istyle}></i></Button>

    <div style={{ ...tstyle,  top:'0', left:'0', color:'goldenrod' }}>
        <p>{part.id}</p>
    </div>

    <div style={{ ...tstyle, top:'97%', left:'90%' }}>
        <p>{page}</p>
    </div>

{ !modal ?
    <Button className="mr-2" variant={"outline-info"}  
        style={{ position:'absolute', width: '6%', aspectRatio:'1 / 1', top:0, right:0, margin:'6px' }}
            onClick={()=>Expand(true)}
            ><i className={"fa fa-expand"} style={{display: 'flex', justifyContent: 'center'}}></i>
            </Button>    
: null }

{ expanded ?
    <ReadModal path={path} part={part} page={page} onPart={onPart} onPage={onPage} show={expanded} onHide={()=>Expand(false)} />
: null }
</>
        :   null
        }
        </div>
    )
}


export default ReadControl

/**
 * {play?"fa fa-pause":"fa fa-play"}
 */