import React,{useState, useEffect} from 'react'
import './Performing.css'
import {Table} from 'react-bootstrap'
import {PerformingModal} from './PerformingModal'
import {toDate, Duration, viewDate} from '../Shared/Formating'
import {OnAction, OnCRUD} from '../Shared/ActionControl'
import {ToNotify} from '../Shared/ViewControl'
import {ToId} from '../Shared/ViewControl'
import {Get} from '../Api'

export const PerformingDetail = ({global, planning, date, onPerform}) =>{

    const entity='Performing'
    const [loaded, Load] = useState(false)
    const [items, Items] = useState([])
    const [notify, Notify] = useState({open:false, message:''})
    const [action, Action] = useState({id:0, crud:0, show:false, parent:planning})

    useEffect(() => {
        if(loaded)
            Load(false)
        
     }, [planning, action.show])

    useEffect(() => {
        if(!loaded) {
            onLoad(planning)
        }
     }, [loaded])

    const onSelect =(id) => {
        Action({...action, id:id})
        onPerform && onPerform(id)
    }

    const onLoad = (planning=0) => {
        let request = planning>0 ? `${entity}/${planning}` : date ? `${entity}/${date}` :  `${entity}`
        //alert(request);   alert(global.search)
        Get(request, Items, Load, Notify)
    }

    const onClose =() => {
        Action({show:false, id:0, crud:0})
    }

    let filteredItems = items.filter(
        
        (item)=>{
            return (item.Course+item.Lesson+item.Activity).indexOf(global.search)!==-1;
        }   
    )
 
    return (        

<div style={{overflow:"scroll", width:"97%", margin:"1%", direction:"rtl", textAlign:"right"}}>
<h6 style={{textAlign:'right'}}>({planning}) قائمة الأداء </h6>

<ToNotify 
message={notify.message}
onClose={()=>Notify({open:false, message:''})}
/>    

{
!loaded ? 
<div>{entity} is loading...</div> 
:
<Table striped hover bordered size="sm">
<thead>
    <tr>
        <th><OnAction rstyle={{justifyContent: 'center'}} onRefresh={()=>Load(false)} />
        </th>
        <th>دورة</th>
        <th>درس</th>        
        <th>نشاط</th>
        <th>تاريخ</th>
        <th>مدة</th>
        <th>ملاحظات {planning}</th>
        <th>تاريخ</th>
        <th>
            <OnAction rstyle={{justifyContent: 'center'}}
            onCreate={()=> Action({...action, show:true, crud:1})} 
            />
        </th>
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }}>
    {
        filteredItems.map(item=>
            <tr key={`${entity}-${item.Id}`}>
<ToId value={item.Id} onClick={()=>onSelect(item.Id)} crud={2} selelcetd={action.id} />
<td>{item.Course}</td>
<td>{item.Lesson}</td>
<td>{item.Activity}</td>
<td>{toDate(item.Date)}</td>
<td>{Duration(item.Duration)}</td>
<td>{item.Description}</td>
<td>{viewDate(item.updatedAt)}</td>
<OnCRUD 
    onEdit={()=> { Action({show:true, id:item.Id, crud:3, parent:planning}) }} 
/>
            </tr>
            )
    }
</tbody>
</Table>

    }

    <PerformingModal
    global={global}
    action={action} // action.show
    onHide={onClose} 
    />

</div>

        )
}