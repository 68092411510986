/* MeR 
First formating 221004
*/

const toDate = (value, year=true) => {
  let date = value ? new Date(value) : new Date()
  date = date.toISOString().split('T')[0]
  return year ? date : date?.substring(5);
}

const toTime = (value, length=5) => {
  let date = value ? new Date(value) : new Date()   
  return date.toTimeString().substring(0,length)
}

const viewDate = (value, time=true) => {
  //alert(value)
    const now = new Date()
    const meDate = value ? new Date(value) : now

    let thisDay = (value) ? (meDate.getDate()===now.getDate()) : true
    if(thisDay && time)
      return meDate.toTimeString().substring(0,5)
        
    let thisYear = (value) ? (meDate.getFullYear()===now.getFullYear()) : true ;
    let date = meDate.toISOString()?.split('T')[0]
    let pdate = thisYear ? date?.substring(5) : date?.substring(2)
    
    return (time) ? pdate+" "+meDate.toTimeString().substring(0,5) : pdate ;
}

const dayName = (value) =>{
  let date = value ? new Date(value) : new Date()
  return date.toLocaleDateString('ar-MA-u-nu-latn',{weekday: 'long'})
//  let date = newDate.toLocaleDateString('ar-EG-u-nu-latn',{weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'});
//  new Intl.DateTimeFormat('ar-TN-u-ca-islamic', {day: 'numeric', month: 'long',weekday: 'long',year : 'numeric'}).format(Date.now());
} 

const Duration = (secondes) => {
  if(secondes>60)
  {
  let h = Math.floor(secondes/3600)
  let m = Math.floor((secondes-h*3600)/60)

  return h.toString().padStart(2,'0')+":"+m.toString().padStart(2,'0') ;
  }
  return "0:00"
}

const addDays = (days, date=null) => {
  var result = date ? new Date(date) : new Date();
  result.setDate(result.getDate() + days);
  return result;
}

/*
Dev casse tête :)
*/
const addMinutes = (mins, date=null) => {
  var result = date ? new Date(date) : new Date()
  result.setMinutes(result.getMinutes() + mins)
  // ignore timezone for now !
  return result.toDateString()+" "+result.toTimeString().substring(0,8)
}

const isEditable = (updatedAt)=> {
  let exemption=addDays(-7)
  let check=(new Date(updatedAt))>exemption
  //console.log('isEditable', check, exemption, updatedAt)   alert(`isEditable ${updatedAt} ${check}`)
  return check
}

const toMinutes = (hhmm) => {
  var hm=hhmm.split(':')
  if(hm.length===2){
      let mins=(hm[0]*60)+(hm[1]*1)
      return mins
  }  
  return 0          
}

const styleRegister = (item) => {
  let sitem = {label:'onRegister', style:{backgroundColor : 'gray'}}
  switch(item.Status) {
      case 1:
          sitem = {label:'طلب التسجيل', style:{backgroundColor : 'goldenrod'}}
          break
      case 2:
          sitem = {label:'طور البحث', style:{backgroundColor : 'cyan'}}
          break
      case 3:
          sitem = {label:'مسجل', style:{backgroundColor : 'greenyellow'}}
          break
      case 4:
          sitem = {label:'التسجيل مسدود', style:{backgroundColor:'darkred', disabled:'true'}}
          break 
      default:
        sitem = {...sitem, label:'غير محدد'}
        break
      
  }
  return sitem
}


/* 221001
https://www.w3schools.com/colors/colors_crayola.asp
B'dazzled Blue  #2E5894
Big Dip O' Ruby #9C2542
Metallic Seaweed    #0A7E8C


Steel Blue	#0081AB

*/
const sectionStyle = (section, border=1, opacity=0.7) => {
    let style ={
        opacity: `${opacity}`,
        border: `${border}px solid`,
        borderRadius:'15px',
        
        borderColor: '#BCC6CC', /* Metallic Silver */
        backgroundColor:'black',
    }
    
    switch(section){
        case 1: 
        style.backgroundColor="#2E5894"
        break

      case 2 : 
        style.backgroundColor="#9C2542"
        break
        
      case 3: 
        style.backgroundColor="#0A7E8C"
        break
      
      case 4: 
        style.backgroundColor="#85754E"
        break

      case 5: 
        style.backgroundColor="#4A646C"
        break

      case 6: 
        style.backgroundColor="#58427C"
        break

      case 7: 
        style.backgroundColor="#757575"
        break

      case 8: 
        style.backgroundColor="#0081AB"
        break

      default:
        style.backgroundColor="#32527b"
        break
    }
    //alert(color)
    return style
}

const toText = (value) => {
  //let cleanNote = note.replace(/<\/?[^>]+(>|$)/g,"")

  let doc = new DOMParser().parseFromString(value, 'text/html') // !
  return doc.body.textContent || ""
}


const activityStyle = (activity=1) => {
  let style ={
      opacity: `1`,
      border: `0px solid`,
      padding:'0.1rem',
      borderRadius:'0px',
      fontSize: '1rem',
      textAlign:'center',
      margin: '0.1rem',
      color: 'silver',
      direction:'rtl',
      borderColor: '#BCC6CC', /* Metallic Silver */
      backgroundColor:'black',
  }
  
  switch(activity){
      case 'تلاوة': 
      style.backgroundColor="#003012" 
      break

    case 'حفظ' : 
      style.backgroundColor="#9C2542"
      break
      
    case 3: 
      style.backgroundColor="#0A7E8C"
      break
    
    case 4: 
      style.backgroundColor="#85754E"
      break

    case 5: 
      style.backgroundColor="#4A646C"
      break

    default:
      style.backgroundColor="#32527b"
      break
  }
  return style
}


export {styleRegister, dayName, toDate, toTime, Duration, sectionStyle, toText, addDays, addMinutes, toMinutes, isEditable, activityStyle, viewDate}