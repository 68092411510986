import React, { useState, useEffect } from 'react'
import './Member.css'
import {Table, Row} from 'react-bootstrap'
import {TeamModal} from '../Team/TeamModal';
import {ToNotify} from '../Shared/ViewControl'
import {ToId} from '../Shared/ViewControl'
import { viewDate, toDate } from '../Shared/Formating';

const MemberTeams = ({global, member}) => {
    const [loaded, Load] = useState(false)
    const [items, Items] = useState([])
    const [message, Message] = useState('')
    const [action, Action] = useState({crud:0, id:0, show:false});

    useEffect(() => {
        if(member>0) {
            global.GET(`/Member/Detail/${member}/Teams`)
            .then(data => {
                Items(data)
                Load(true)
                Message(`${data.length} teams loaded !`)     
            })
            .catch(error => {
                Message(error) 
            })
        }
    }, [member, loaded])
 
        return (
<>

<ToNotify message={message} onClose={()=>Message('')} />

{ loaded ?
    <div style={{overflowY:"scroll", width:"98%", margin:"1%", border:"1px", direction:"rtl"}}>
        <h6 style={{textAlign:'right'}}>الفرق</h6>
<Table striped hover bordered size="sm" style={{width:'inherit'}}>
<thead>
    <tr>
        <th>رقم</th>
        <th>الفريق</th>
        <th>الدور</th>
        <th>الأعضاء</th>
        <th>القائدة</th>
        <th>المشرفة</th>
        <th>تاريخ</th>
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }}>
    {
        items.map(item=>
            <tr key={item.Id}>

<ToId value={item.Id} onClick={()=> Action({id:item.Id, crud:2, show:true})} />
<td>{item.Name}</td>
<td>{item.Role}</td>
<td>{item.members}</td>
<td>{item.Leader}</td>
<td>{item.Supervisor}</td>
<td>{viewDate(item.updatedAt)}</td>
</tr>
            )
    }
</tbody>
</Table>

{action?.id>0 ?
        <TeamModal
        global={global}
        action={action}
        onHide={()=>Action({id:0, crud:0, show:false})}
        />
: null}

</div>
: <h6>Loading for {member} ...</h6> }
</>
        )
}

const MemberClasses = ({global, member}) => {
    const [loaded, Load] = useState(false)
    const [items, Items] = useState([])
    const [message, Message] = useState('')
    const [action, Action] = useState({crud:0, id:0, show:false});

    useEffect(() => {
        if(member>0) {
            global.GET(`/Member/Detail/${member}/Classes`)
            .then(data => {
                Items(data)
                Load(true)      
            })
            .catch(error => {
                Message(error) 
            })
        }
    }, [member, loaded])
 
        return (
  
<>
<ToNotify message={message} onClose={()=>Message('')} />

{ loaded ?
    <div style={{overflowY:"scroll", width:"98%", margin:"1%", border:"1px", direction:"rtl"}}>
        <h6 style={{textAlign:'right'}}>الأقسام</h6>
<Table striped hover bordered size="sm" style={{width:'inherit'}}>
<thead>
    <tr>
        <th>رقم</th>
        <th>الدورة</th>
        <th>الدرس</th>
        <th>المعلمة</th>
        <th>البدء</th>
        <th>الانتهاء</th>
        <th>ملاحظات</th>   
        <th>المتابعات</th>
        <th>تاريخ</th>
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }}>
    {
        items.map(item=>
            <tr key={item.Id}>

<ToId value={item.Id} onClick={()=> Action({id:item.Id, crud:2, show:true})} />
<td>{item.Course}</td>
    <td>{item.Lesson}</td>
    <td>{item.Master}</td>
    <td>{toDate(item.StartDate)}</td>
    <td>{toDate(item.EndDate)}</td>
    <td>{item.Description}</td>
<td>{item.members}</td>
<td>{viewDate(item.updatedAt)}</td>
</tr>
            )
    }
</tbody>
</Table>

{action?.id>0 ?
        <TeamModal
        global={global}
        action={action}
        onHide={()=>Action({id:0, crud:0, show:false})}
        />
: null}

</div>
: <h6>Loading for {member} ...</h6> }
</>
        )
}

export {MemberTeams, MemberClasses}