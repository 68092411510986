import React,{useState,useEffect} from 'react';
import './Member.css';
import {Col, Row} from 'react-bootstrap';
import {MemberModal} from './MemberModal';
import {MemberTeams, MemberClasses} from './MemberDetail';
import {OnAction} from '../Shared/ActionControl'
import { toDate } from '../Shared/Formating'

const MemberGrid = ({global, onMember, Message, search}) =>{

    const entity='Member'
    const [loaded, Load] = useState(false)
    const [items , Items] = useState([])
    const [action, Action] = useState({id:0, crud:0, show:false})

    useEffect(() => {
        if(!loaded){
            //Message("Load .")
            global.GET(entity)
            .then(data => {
                Items(data)
                Load(true)
                //console.log('Members',data)
            })
            .catch(error => {
                Message(error)   
            })    
        }
     }, [loaded])

    const onSelect =(member) => {
        Action(member)
        onMember && onMember(member.id)
    }
    
    let filtered = items.filter(
        (item)=>{
            return (item.Name+item.Phone+(item?.Infos??"")).indexOf(search)!==-1;
        }
    )

    const onClose =() => {
        Action({show:false, id:0, crud:0})
        Load(false)
    }

        return (
            <>

<div className="Members">
{
        filtered.map(item=>
            <Member key={`Member-${item.Id}`}
            item={item}
            avatar={"./images/hifd.png"}
            onAction={onSelect} />
        )
}
</div>

<MemberModal
    global={global}
    id={action.id}
    mode={action.crud}
    show={action.show}
    onHide={onClose}
    />

{ action?.id > 0 ?
<>
    { action.crud===21 ?
    <MemberTeams global={global} member={action.id}  /> 
    : null } 

{ action.crud===22 ?
    <MemberClasses global={global} member={action.id}  /> 
    : null } 
</>
: 
null }


</>
        )
}

const Member = ({item, avatar, onAction}) => {
    const cstyle={fontSize: '1rem', justifyContent:'center', alignItems:'center', fontWeight: 'bold', color:'blue' }
    const istyle={fontSize: '0.8rem', color: 'blue', justifyContent:'center', textAlign:'center', border:'1px solid white', borderRadius:'12px', padding:'6px'}
    //alert(name)

    return (
        <div className='Member' >
            <Col>
            <div className="center">
                <img src={avatar} alt={item.Name+":"+item.Phone} />
            </div>
                <Row style={{...cstyle, color:'gray'}}>{item.Title}</Row>
                <Row style={{...cstyle, fontSize: '1.2rem'}} onClick={()=>onAction({id:item.Id, crud:0, show:false})}>{item.Name}</Row>
                <Row style={{marginTop:'0.5rem'}}>
                <Col sm={4} style={cstyle}>{item.Country}</Col>
                <Col sm={8} style={cstyle}>{item.Phone}</Col>
                </Row>
                <Row style={{...cstyle, color: 'black'}}>{item.Email}</Row>
                
                <Row style={{...cstyle, fontSize: '0.8rem', color: 'black', margin:'1rem 0 1rem 0'}}>{item.Infos}</Row>
                

                <Row>
                <Col sm={7} style={{...cstyle, color: 'gray'}}>{toDate(item.Enrollmented).substring(2)}</Col>
                <Col sm={5} style={{...cstyle, color: 'gray'}}>{item.Role ?? `عضو ${item.members}`}</Col>
                </Row>
                <Row style={{margin:'1rem 0 1rem 0'}}>
                <Col sm={6} style={istyle} onClick={()=>onAction({id:item.Id, crud:22, show:false})}>{item.classes} قسم</Col>
                <Col sm={6} style={istyle} onClick={()=>onAction({id:item.Id, crud:21, show:false})}>{item.teams} فريق</Col>
                </Row>
            </Col>
            <OnAction 
            onEdit={ ()=>onAction({id:item.Id, crud:3, show:true}) } 
            onExpand={ ()=>onAction({id:item.Id, crud:2, show:true}) }
            />             
        </div>
    )
}

export {MemberGrid}
