import React,{useState, useEffect} from 'react';
import {Modal,Button,Row,Col,Form} from 'react-bootstrap';
import {InText, InDetail} from '../Shared/InputControl'
import OptionsControl from '../Shared/OptionsControl';
import {toMinutes, addDays, addMinutes} from '../Shared/Formating'
import {OnSubmit} from '../Shared/ActionControl'
import {ModalHeader, ModalFooter} from '../Shared/ModalControl'
import {Fetch} from '../Api'
import { AppointmentControl, DurationControl } from '../Shared/Appointment';

export const PlanModal = ({global, action, onHide, Message}) => {
    const entity='Planning'
    const readOnly = action.crud===2
    const [item, Item] = useState(null)
    const [loaded, Load] = useState(false)

    useEffect(() => {
        if(!loaded) {
            onLoad()
        }
    }, [loaded])
  
    const onLoad = () => {
        if(action?.show) {
            if(action?.id > 0) {
                global.GetItem(entity, action.id)
                .then((data) =>  {
                        Item(data)
                        Load(true)
                        //console.log('PlanModal.Load', data)
                    }
                )
                .catch(error => {
                    Message(error)   
                })
            }
            else if(action?.crud===1) {
                Item({Id:0, TeamId:2, ClasseId:3, Subject:'',Date:addDays(0), Duration:3600})
                Load(true)
            }
        }
    }    

    const onFetch = () => {
        console.log('PlanModal.onFetch', item, action); alert('PlanModal ' + item.Subject)
        Fetch(entity, item, action.crud, Load, Message)
    }
    

        const onChange = (e) => {
            e.preventDefault()

            document.cookie = `me${e.target.name}=${e.target.value}`
            //console.log('Modal ', e.target); alert(`change ${e.target.name} - ${e.target.value}`)

            Item({...item, [e.target.name]: e.target.value})            
        }

        const onStat = (name,value)=>{
            //alert("onStat "+name+": "+value)
            if(name==="duration"){
                let secs=toMinutes(value)*60
                Item({...item, Duration:secs})
            }
            else if(name==="startTime")
            {
                Item({...item, Date:value})
            }
        }


        return(
            <div className="container">
 
            <Modal
            show={action.show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >

<ModalHeader title={"معلومات التخطيط"} id={action.id} />

            <Modal.Body>

 <Row>
     <Col sm={11} >
         {
loaded && item 
?
         <Form onSubmit={onFetch}>

<OptionsControl entity={'Team'} id={item.TeamId} onChange={onChange} readOnly={readOnly} label='فريق'/> 
<OptionsControl entity={'Classe'} id={item.ClasseId} onChange={onChange} readOnly={readOnly} label='درس'/> 

<div className="row"  style={{direction:'rtl'}}>  
    <div className="col-lg-6">
<AppointmentControl  
  label={"الموعد"}
  name="startTime"
  defaultvalue={item.Date}
  onDateTime={onStat}
/>
</div>

<div className="col-lg-6" style={{left:0}}>
<DurationControl   
label={"المدة"}
  name="duration"
  type="number"
  defaultvalue={item.Duration}
  onDuration={onStat}
  />
</div>
</div>
<InDetail name="Subject" value={item.Subject} onChange={onChange} readOnly={readOnly} label={"ملاحظات"} />

            <OnSubmit crud={action.crud} /> 

         </Form>
: 
<div>{entity} is loading...</div>
        }
     </Col>
 </Row>

            </Modal.Body>
            <ModalFooter onHide={onHide} />
          </Modal>
          </div>  
        )
}

