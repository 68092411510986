import React,{useState, useEffect} from 'react'
import './meTree.css'
import {onGet} from '../Shared/Akademy'
import Config from '../Config/ar'

const TreeView = ({onChange, page, entity='Nodes', course='1', levels=4}) => {

    const [loaded, Load] = useState(false)
    const [items, Items]= useState([])
    const [tree, Tree]= useState([])
    const [selected, Select]= useState([0,0,0,page])
    
    //alert(`selected:${selected} course:${course}`)
    
    useEffect(() => {
        let request=`/waqafaat/${entity}&${course}`
        //alert(request)
        onGet(request, Items, Load)
    }, [course])    

    useEffect(() => {
        if(loaded && items?.length>0) {

            let course, joze, soora, nodes = []
            let nc=0, nj=0, ns=0
            
            items.forEach(item => {
                if(nc!==item.CourseId){
                    nodes.push({id:item.CourseId, text:`${item.CourseId} - ${item.Course}`, page:item.Safha, nodes:[]})
                    nc=item.CourseId
                    course=nodes[nodes.length-1]
                    nj=0
                }
                
                if(nj!==item.Joze){
                    course.nodes.push({id:item.Joze, text:`${'جزء'} ${item.Joze}`, page:item.Safha, nodes:[]})
                    nj=item.Joze
                    joze = course.nodes[course.nodes.length-1]
                    ns=0
                }
                
                if(ns!==item.SooraId){
                    joze.nodes.push({id:item.SooraId, text:`${item.SooraId} - ${item.Soora}`, page:item.Safha, nodes:[]})
                    ns=item.SooraId
                    soora=joze.nodes[joze.nodes.length-1]
                }
                let title = `${item.Ila} - ${item.Min} (مقطع - ${item.Sahs})`
                soora.nodes.push({id:item.Safha, text:`${item.Safha}`, title:title})
            })
            Tree(nodes)

            //console.log('Tree',nodes); alert(items.length)
        }
    }, [loaded])    


    const onCollapse = (item, level) => {
        const ul = item?.querySelector('ul');
        if (ul) {
            var nodes = item.querySelectorAll(`.Node${level+1}`)
            nodes.forEach(function(node) {
                if (node.classList.contains('collapsed')) {
                    node.classList.remove('collapsed');    
                } else {
                    node.classList.add('collapsed');
                }
            })
        }

        if(item && !item.classList.contains('selected')) {
            const lis = document.getElementById('meTree').querySelectorAll('li')
            lis.forEach(li => {
                if (li.classList.contains('selected')) {
                    li.classList.remove('selected')
                }
            })
            item.classList.add('selected')  
        }
        
    }  
     
     
    const onNode = (e, level, id) => {
        onCollapse(e?.target?.parentElement, level)
        
        //alert(`e=${e.target.id} l=${level}-${id} `)
            //let level=e.target.id
        if(level>0) {
            
            let levels = selected
            levels[level-1]=id
            for(let l=level; l<selected.length;l++)
                levels[l]=0

            Select(levels)
            onChange && onChange(e, levels)       
        }
    }
 
    return (
        loaded ? 
        <div id="treeContainer">   
        <span className="label" style={{fontSize:'1.2rem'}} onClick={(e) => onNode(e,0,0)} id={`Moshaf-${tree.length}`}>{Config.bismi}</span>
        <ul id="meTree">
        { tree.map(course => (
                <li className="Node1" value={course.id}>
                    <span className="label" onClick={(e) => onNode(e,1,course.id)} id={`Course-${course.id}`}>{course.text}</span>
                <ul>
                { course.nodes.map(joze => (
                    <li className='Node2 collapsed'>
                        <span className="label" onClick={(e) => onNode(e,2,joze.id)} id={`Joze-${joze.id}`}>{joze.text}</span>

                    <ul>
                    { joze.nodes.map(soora => (
                        <li className='Node3 collapsed'>
                            <span className="label" onClick={(e) => onNode(e,3,soora.id)} id={`Soora-${soora.id}`}>{soora.text}</span>
                        
                        <ul>
                        { levels>3 && soora.nodes.map(safha => (
                            <li className='Node4 collapsed' >
                                <span className="label"  title={safha.title} onClick={(e) => onNode(e,4,safha.id)} id={`Safha-${safha.id}`}>{safha.text}</span>
                            </li>

                            ))
                        }                
                        </ul>
                        </li>
                        ))
                    }                
                    </ul>
                    </li>
                ))
                }                
                </ul>
                
                </li>
            ))
        }
    </ul> 
    </div> 

    : <div>{entity} is loading...</div> 
    )
}

export default TreeView