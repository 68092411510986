import React from 'react'
import './Service.css'
import {NavLink} from 'react-router-dom'

const Services = ({connected}) => {

    return (
        connected ?  
<div className='Services' >

<Service name="إحفظ" 
    icon="./images/hifd.png" 
    to="/memorizing"
    />

<Service name="التكرار" 
    icon="./images/tikraar.png" 
    to="/repetition"
    />

<Service name="الدروس" 
    icon="./images/tajwyd.png" 
    to="/course"
    />

<Service name="حجز المرور" 
    icon="./images/hifd.png" 
    to="/booking"
    />
</div>
:<h1>مرحبًا، الرجاء الدخول أو التسجيل</h1>
    )

}

const Service = ({name, icon, info='', plan='', start='', to, style}) => {

    //alert(info)

    return (
        <div className='Service' style={style}>
            <div className="center">
                { 
                to.startsWith('http') ?
                <a target="_blank" rel="noopener noreferrer" href={to}>
                    <img src={icon} alt={name+": "+info} />
                </a>
                :
                <NavLink to={to} >
                    <img src={icon} alt={name+": "+info} />
                </NavLink>
                }
            </div>
            <br/>
            <div className="ServiceName">
            { 
                to.startsWith('http') ?
                <h1 style={{fontWeight: 'bold'}}>{name}</h1>
                :
                <NavLink to={to} >
                    <h1>{name}</h1>
                </NavLink>
                }
                <h4 className="ServiceInfo">{info}</h4>
                <br/>
                <h2 className="ServicePlan">{plan}</h2>

                {style && start?.length>0 ?
                <div style={{width:'100%', position:'relative'}}>
                <a href={to} target="_blank" rel="noreferrer" >
                    <button className='Button'
                        style={to.includes('telegram') ? {backgroundColor:'rgb(40,170,240)'} : null }
                    >
                    {start}
                    </button>
                </a>
                </div>
                : null }
            </div>
        </div>
    )
}


export {Service, Services}


/*
<Button onClick={() => { 
window.open({to}, '_blank')
return false
}}>{start}</Button>

by return false we can remove/cancel the default behavior of the button like submit.

 */