import React,{useState, useEffect} from 'react';
import {Table} from 'react-bootstrap';
import {TeamDetail} from './TeamDetail';
import {TeamModal} from './TeamModal';
import {ToNotify} from '../Shared/ViewControl'
import {OnAdd, OnCRUD} from '../Shared/ActionControl';
import {ToDate,ToId,ToClick} from '../Shared/ViewControl';
import { TeamMembers } from './TeamMembers';
import {Get} from '../Api';

export const Teams = ({global, role, Etat, onTeam}) =>{
    const entity='Team'
    const [loaded, Load] = useState(false)
    const [items, Items] = useState([])
    const [message, Message] = useState('')
    const [action, Action] = useState({id:0, crud:0, show:false})

    useEffect(() => {
        //onLoad()
        if(!loaded) {
            sessionStorage.setItem("routeCurrent","/team")

            Get(entity, Items, Load, Message)
            Etat(message)
        }
     }, [loaded])

    const onSelect =(id) => {
        Action({id:id, crud:6, show:false})
        onTeam && onTeam(id)
    }

    const onDelete = (id) => {
        global.DELETE(entity, id)
        .then(()=> Load(false))
    }
        
    let filteredItems = items.filter(    
        (item)=>{                
            return (item.Name+item.Description+item.Leader+item.Supervisor).indexOf(global.search??"")!==-1;
        }            
    )

    const onClose =() => {
        Action({show:false, id:0, crud:0})
        Load(false)
    }

    return (
            <>
<div className="FullScreen" style={{height:"40vh"}}>
<Table striped hover bordered size="sm">
<thead>
    <tr>
        <th>رقم</th>
        <th>الإسم</th>
        <th>تقديم</th>
        <th>القائدة</th>
        <th>المشرفة</th>
        <th>الأعضاء</th>        
        <th>تاريخ</th>
        <OnAdd onClick={()=>Action({show:true, id:0, crud:1})}/>
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }} key='Teams'>
    {
        filteredItems.map(item=>
<tr key={item.Id}>

<ToId value={item.Id} onClick={()=> onSelect(item.Id)} selelcetd={action.id}/>
<ToClick value={item.Id} text={item.Name} onClick={()=> Action({id:item.Id, crud:5, show:false})} />
<td>{item.Description}</td>
<td>{item.Leader}</td>
<td>{item.Supervisor}</td>
<td>{item.members}</td>
<ToDate value={item.updatedAt} />

<OnCRUD 
    onInfo={()=> Action({show:true, id:item.Id, crud:2})} 
    onEdit={()=> Action({show:true, id:item.Id, crud:3})} 
    onDel= {role>6 ? ()=> onDelete(item.Id) : null} 
/>
</tr>
        )
    }
</tbody>
</Table>
</div>

{ action.id>0 ?
    action.crud===5 ?
        <TeamDetail team={action.id} global={global} Message={Message} />
    : 
        <TeamMembers team={action.id} role={2}/> 
: null }


{action.show ?
    <TeamModal
    global={global}
    action={action}
    onHide={()=> onClose}
    />
: null}

<ToNotify message={message} onClose={()=>Message('')} />
</>
        )
    }