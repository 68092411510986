import React,{useState, useEffect, useLayoutEffect} from 'react'
import { Form } from 'react-bootstrap'
import {Get, Cookie} from '../Api'

const OptionsControl = ({entity, id, label, onChange, readOnly=false}) => {

    const name = entity+"Id"
    const [loaded, Load] = useState(false)
    const [items, Items]= useState([])
    const [value, Value] = useState(Cookie(`me${name}`) ?? id)

    useEffect(() => {
        if(!loaded){
            Get(`Setting/${entity}`, Items, Load)
        }
     }, [loaded])
     
    useLayoutEffect (()=>{  
        //alert(name)
        /*
        const element = document.getElementById(name)
        if(element) {
            let value = Cookie(`me${name}`)        
            if(value) {
                alert(`Cookie ${name} = ${value}`)
                element.defaultValue = value
            }
        }
        */
    })

    const onSelect=(event) =>{
        if(event?.target) {
            onChange && onChange(event)
        }
    }
 
    return (
        loaded ? 
        <Form.Group className='form-row'>
            
            <Form.Control as="select" name={name} className="col-sm-9 text-right" 
                onChange={onSelect} defaultValue={value} readOnly={readOnly} disabled={readOnly} >
                {
                    items.map(item => 
                    <option key={item.Id} value={item.Id}>{item?.Value ?? item.Name}</option>
                    )
                }
            </Form.Control>
        
            <Form.Label className='col-sm-3' align='right'>:{label ?? entity}
            </Form.Label>
        </Form.Group> 
        
        : <div>{entity}s is loading...</div> 
    )
}

export default OptionsControl