import React, { useState, useEffect } from 'react'
import {Modal,Button,Col} from 'react-bootstrap'
import '../Reading/Reading.css'
import {onGet, getPage} from '../Shared/Akademy'


const Wajhs = ({course, joze, soora, safha, onPage, selected}) => {
    const [pages , Pages] = useState([])
    const [loaded, Load] = useState(false)
    
    useEffect(() => {
        if(course>1) {
            let request = `/waqafaat/Memorizing&${course}&${joze??0}&${soora??0}`
            //alert(request)
            onGet(request, Pages, Load)
        }
    }, [course, joze, soora])  
    
        return (
loaded && pages.length>0 ?            
            <Col className='pages' style={{maxHeight:'calc(100% - 50px)'}}>
    <div className="thumbnails-grid" key={`wajhs-${course}`} >
        {             
    pages.map(item => 
        <div align="center" valign="center" className="thumbnails-pane" key={`wajh-pane-${item.Safha}`}  
                //style={{backgroundImage:`url(${item.path})`}}
        >
            <img className="thumbnails-image" 
                key={`wajh-img-${item.Safha}`} 
                id={item.Safha} 
                value={item.Safha} 
                onClick={()=>onPage(item)} 
                alt={item.Safha}
                src={getPage(item.Safha)} 
                style={{border: (safha===item.Safha) ? '2px solid red' : selected.includes(item.Safha) ?'2px solid gold':''}}
            />

            <div className={selected.includes(item.Safha)?"thumbnails-info thumbnails-select":"thumbnails-info"}>
                {item.Safha}
            </div>
                
        </div>   
    )         
        }  
    </div>   
    </Col>
: 
<h5>loading...</h5>     
)}

const WajhModal = ({wajh, show, onHide}) => {


    return (
  
  <div className="page-center">
  
          <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby = "القرآن الكريم"
            animation={false}
            style={{margin:'3rem auto 0 auto', height:'100%'}}
            centered
          >
              
              <img className="page-fit page-fit-center" style={{maxHeight:'80vh', margin:'5px auto'}} id="safha" alt="القرآن الكريم" 
          src={wajh} 
          />
  
  
  
      <Button className="mr-2" variant={"outline-danger"}  
          style={{ position:'absolute', width: '8%', aspectRatio:'1 / 1', top:'2%', left:'90%' }}
          onClick={onHide}
      >
          <i className={"fa fa-close"} style={{display: 'flex', justifyContent: 'center'}}></i>
      </Button>
  
  </Modal>
  
  </div>
  )}
  
export {Wajhs, WajhModal}



