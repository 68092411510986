import React,{useState} from 'react'
import {FormControl} from 'react-bootstrap';

const SearchControl = ({onSearch}) => {

    const [showing, Showing] = useState(false)

    const onChange = (e) => {
        onSearch(e.target.value)
    }

    const onClick = (e) => {
        if(showing)
            onSearch("")
            
        Showing(!showing)
    }

    return (
        <>
                        
        <i className="fa fa-search" onClick={onClick} ></i>

        { showing ?       
            <FormControl 
                className="d-inline" 
                style={{ textAlign: 'center', backgroundColor:'lightyellow', width:'6rem', fontWeight:'bold' }} 
                name="Search" type="text" 
                defaultValue=""
                onChange={onChange}
                placeholder="بحث" />
        : null }

        </>
    )
}

export default SearchControl
