import React, {useState, useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import {Navbar} from 'react-bootstrap'

export const Navigation = ({global, onRole, route, layout}) =>{

    const [role, Role] = useState(0)
    const [loaded, Load] = useState(false)
    const [expanded, Expanded] = useState(false)
    const [items , Items] = useState([])

    useEffect(() => {
      //alert('Navigation')
      global.GET(`Setting/Authorization`)
      .then(data => {
          Items(data)
          if(data && data.length>0){
            Role(data[0].RoleId)

            onRole && onRole(data[0].RoleId)

            //  console.log('Authorization', data, role)
          }
          Load(true)
      })
      .catch(error => {
          console.log(error)
      })    
   }, [loaded])

    

  const Logout = (e) => {
      localStorage.removeItem('Akademy')
      global.store=null
      window.location.reload()
  }


    const navItems = (
      role>0 ?
        <Navbar.Collapse id="basic-navbar-nav" onClick={()=>Expanded(!expanded)}>
          <NavLink className="navbar-custom" to="/" onClick={Logout} >
                <i className="fa fa-sign-out"> خروج</i>
          </NavLink>

          <NavLink className="navbar-custom" to="/course">الدورات</NavLink>
        
          {role>1 ?
          <>
          <NavLink className="navbar-custom" to="/meditating" >تدبُّر</NavLink> 
          <NavLink className="navbar-custom" to="/repetition" >تكرار</NavLink> 
          <NavLink className="navbar-custom" to="/memorizing" >إحفظ</NavLink> 
          <NavLink className="navbar-custom" to="/reading" >ٱقۡرَأۡ</NavLink>
          <NavLink className="navbar-custom" to="/performing" >تنفيذ</NavLink>
          <NavLink className="navbar-custom" to="/booking" >حجز المرور</NavLink>
          </>
          :null}

          {role>2 ? 
          <>
          <NavLink className="navbar-custom" to="/evaluation" >التقييم</NavLink>  
          <NavLink className="navbar-custom" to="/team" >الفرق</NavLink>
          <NavLink className="navbar-custom" to="/subject" >المواضيع</NavLink>
          <NavLink className="navbar-custom" to="/planning" >تخطيط</NavLink>    
          </>
          :null}

          {role>6 ?
          <>
          <NavLink className="navbar-custom" to="/setting" >إعدادات</NavLink>
          <NavLink className="navbar-custom" to="/person"  >الأعضاء</NavLink>
          <NavLink className="navbar-custom" to="/managing" >إدارة</NavLink>
          <NavLink className="navbar-custom" to="/registering" >مُسجَّلون</NavLink>
          </>
          :null}

        </Navbar.Collapse>
      : null 
    ) 


  return (
    
        layout ? 
        <Navbar>
          {navItems}
        </Navbar>
        :
      
        <Navbar className="navbar-down" expand='lg' expanded={expanded}>
          <Navbar.Toggle className="navbar-dark bg-inverse" onClick={()=>Expanded(!expanded)} />
          {navItems}
        </Navbar> 
    )
}


const Dropdown = ({ submenus }) => {
    return (
      <ul className="dropdown">
        {submenus.map((submenu, index) => (
          <li key={index} className="menu-items">
            <a href={submenu.url}>{submenu.title}</a>
          </li>
        ))}
      </ul>
)}
