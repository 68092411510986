import React, { useEffect, useState } from "react";
import { Duration } from "./Formating";

const RequiredHtml = () => {
  return (
    <i
      class="fa fa-star-of-life text-danger icon-l ml-2"
      style={{ fontSize: "8px", position: "absolute" }}
    ></i>
  )
}

function minOr30(hm) {
  try {
    let a = hm?.split(":");
    let b = parseInt(a[1]);
    //alert(hm)
    if(b===0 || b===30)
      return hm.substring(0,5)+":00"
    else {
      let c = b<30 ? "00" : "30";
      return `${a[0]}:${c}:00`;
    }
  } catch (err) {
    return "00:00:00";
  }
}

const lstyle={  color:"darkred",  textAlign: "center", width:"100%"   }

export function AppointmentControl({
  field,
  label,
  span,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  required,
  ClassLabel,
  onDateTime,
  defaultvalue,
  className="w-100 pt-2",
  ...props
}) 
{
  const { name, disabled } = props;

  useEffect(() => {
    if(defaultvalue) {
      var date = new Date(defaultvalue) 

      let pdate=date.toISOString()?.split('T')[0]
      let ptime=date.toTimeString().substring(0,8)
      //alert('padte: '+ pdate + 'ptime: ' + ptime )

      document.getElementById("Date"+name).value = pdate
      document.getElementById("Time"+name).value = minOr30(ptime)
    }

  }, [defaultvalue])

  return (
    <>
      <div className="row" style={{direction:'rtl', padding:'2rem 0rem 1rem 0rem'}}>
      <h5>{label}</h5>
        <div className="col-lg-6">
          <input
            id={"Date"+name}
            type={"date"}
            className="form-control"
            onBlur={(event) => {
              if (onDateTime && event.target?.value) {
                let ptime=document.getElementById("Time"+name)?.value
                if(ptime?.length!==8)
                  ptime="08:00:00"
                //alert(name+" onBlur "+ptime)
                onDateTime(name, event.target.value + "T" + ptime);
              }
            }}
          />
          {label && ( <label style={lstyle}>{"اليوم"} {required && <RequiredHtml />}</label>
          )}

        </div>
        <div className="col-lg-4">
          <select
            id={"Time"+name}
            className="form-control"
            disabled={disabled}
            defaultValue="06:00:00"
            onChange={(e) => {
                //alert(e.target.value)
                if (onDateTime && e.target?.value?.length===8) {
                  var partdate=document.getElementById("Date"+name)
                  var date = partdate.value?.split("T")[0];
    
                  onDateTime(name, date + "T" + e.target.value);
                }
            }}
          >
            <option value="05:00:00">5:00</option>
            <option value="05:30:00">5:30</option>
            <option value="06:00:00">6:00</option>
            <option value="06:30:00">6:30</option>
            <option value="07:00:00">7:00</option>
            <option value="07:30:00">7:30</option>
            <option value="08:00:00">8:00</option>
            <option value="08:30:00">8:30</option>
            <option value="09:00:00">9:00</option>
            <option value="09:30:00">9:30</option>
            <option value="10:00:00">10:00</option>
            <option value="10:30:00">10:30</option>
            <option value="11:00:00">11:00</option>
            <option value="11:30:00">11:30</option>
            <option value="12:00:00">12:00</option>
            <option value="12:30:00">12:30</option>
            <option value="13:00:00">13:00</option>
            <option value="13:30:00">13:30</option>
            <option value="14:00:00">14:00</option>
            <option value="14:30:00">14:30</option>
            <option value="15:00:00">15:00</option>
            <option value="15:30:00">15:30</option>
            <option value="16:00:00">16:00</option>
            <option value="16:30:00">16:30</option>
            <option value="17:00:00">17:00</option>
            <option value="17:30:00">17:30</option>
            <option value="18:00:00">18:00</option>
            <option value="18:30:00">18:30</option>
            <option value="19:00:00">19:00</option>
            <option value="19:30:00">19:30</option>
            <option value="20:00:00">20:00</option>
            <option value="20:30:00">20:30</option>
            <option value="21:00:00">21:00</option>
            <option value="21:30:00">21:30</option>
            <option value="22:00:00">22:00</option>
            <option value="22:30:00">22:30</option>
          </select>
          {label && <label style={lstyle}>{"الساعة"}</label>}
        </div>
      </div>
    </>
  );
}

export function DurationControl({
  field,
  label,
  type = "text",
  required,
  ClassLabel,
  onDuration,
  defaultvalue,
  className="w-100 pt-2",
  ...props
}) {
  const { name, disabled } = props;
  const [mins, Mins] = useState("00") 

  useEffect(() => {

    if (defaultvalue) {
      //alert('DurationControl defaultvalue: ' + defaultvalue + ' type: '+type)
      var ph=document.getElementById("H-"+name)
      var pm=document.getElementById("M-"+name)

      if(type==="number")
      {
        let hm=Duration(defaultvalue)
        //alert(hm)
        var phm = hm.split(':')
        ph.value = phm[0]??'00'
        pm.value = phm[1]??'05'
      }
      else
      {
        var dhm = defaultvalue.split(':')
        ph.value = dhm[0]??'00'
        pm.value = dhm[1]??'15'
      }
    }
  }, [defaultvalue]);

  useState(()=>{
    var ms = document.getElementById("M-"+name)
    if(ms) {
      //alert(`before ${mins}`)
      ms.value = mins
      //ms.defaultValue = "00"   
      //alert(`after ${pm}`) 
    }   
  },[mins])


  return (
    <>
      <div className="row" style={{padding:'2rem 0rem 1rem 0rem', direction:'rtl'}}>
      <div className="col-lg-3" style={{textAlign:'left'}}><h5>{label}</h5></div>
        <div className="col-lg-4">
          <select
            id={"H-"+name}
            className="form-control"
            disabled={disabled}
            defaultValue="0"
            onChange={(e) => {
              if (onDuration) {
                if(e.target?.value>0) {
                  Mins("00")
                }   
                onDuration(name, e.target.value + ":00")
              }
            }}
          >     
            <option value="00">0</option>
            <option value="01">1</option>
            <option value="02">2</option>
            <option value="03">3</option>
            <option value="04">4</option>
            <option value="05">5</option>
            <option value="06">6</option>
            <option value="07">7</option>
            <option value="08">8</option>
            <option value="09">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
          {label && <label style={lstyle}>ساعات</label>}
        </div>

        <h5>:</h5>

        <div className="col-lg-4">
          <select
            id={"M-"+name}
            className="form-control"
            disabled={disabled}
            defaultValue="15"
            onChange={(e) => {
              if (onDuration) {
                var ph=document.getElementById("H-"+name)?.value ?? "0"            
                onDuration(name, ph + ":" + e.target.value)
              }
            }}
          >     
            <option value="00">0</option>
            <option value="05">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="45">45</option>
          </select>
          {label && <label style={lstyle}>دقائق {required && <RequiredHtml />}</label>}
        </div>
      </div>
    </>
  );
}
