import React,{useState,useEffect} from 'react';
import './Member.css';
import {Table} from 'react-bootstrap';
import {Container, Row, FormControl} from 'react-bootstrap';
import {MemberModal} from './MemberModal';
import {MemberTeams} from './MemberDetail';
import {ToDate, ToId, ToClick} from '../Shared/ViewControl'
import {OnAction, OnCRUD} from '../Shared/ActionControl'

export const Members = ({global, search, onMember, role=2}) =>{

    const entity='Member'
    const [loaded, Load] = useState(false)
    const [items , Items] = useState([])
    const [notify, Notify] = useState({open:false, message:''})
    const [action, Action] = useState({id:0, crud:0, show:false})


    useEffect(() => {
        onLoad()
     }, [loaded, action.show])

    const onSelect =(id) => {
        Action({...action, id:id})
        onMember && onMember(id)
    }

    const onLoad = () => {
        let request = entity
        if(role && role===1)
            request=`${entity}/Registered`

        //alert(request)
        global.GET(request)
        .then(data => {
            Items(data)
            Load(true)
            //console.log('Members',data)
        })
        .catch(error => {
            Notify({open:true,message:error})   
        })    
    }
    
    const onDelete = (id) =>{
        global.DELETE(entity, id)
        .then(Load(false))
    }

    const onChange = (e) => {
        e.preventDefault()
        //Search(e.target.value)
    }
    
    let filteredItems = items.filter(

        (item)=>{
            return (item.Name+item.Phone+item.Country+item.Infos+item?.Email).indexOf(search)!==-1;
        }
        
    );

    let onClose =() => {
        Action({show:false, id:0, crud:0})
        Load(false)
    }


 
        return (
            <Container>
                <Row style={{ margin: '10px' }}>

    <FormControl style={{ textAlign: 'center', backgroundColor:'HighlightText' }} type="text" placeholder="بحث" 
    value={search} onChange={onChange}/>

</Row>

<div style={{overflow:"scroll", width:"97%", margin:"1%", direction:"rtl", textAlign:"right"}}>
<Table striped hover bordered size="sm">
<thead>
    <tr>
        <th>رقم</th>
        <th>الإسم</th>
        <th>اللقب</th>
        <th>الهاتف</th>
        <th>البلد</th>
        <th>الدور</th>
        <th>تقديم</th>
        <th>البريد</th>
        <th>تاريخ</th>
        <th>            
            <OnAction rstyle={{justifyContent: 'center'}}
            onCreate={()=> Action({show:true, id:0, crud:1})} 
            />
        </th>
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }}>
    {
        filteredItems.map(item=>
            <tr key={item.Id}>

<ToId value={item.Id} onClick={()=> Action({show:false, id:item.Id, crud:5})} />
<ToClick value={item.Id} text={item.Name} onClick={()=> Action({show:false, id:item.Id, crud:5})} />
<td>{item.Title}</td>
<td>{item.Phone}</td>
<td>{item.Country}</td>
<td>{item.Role}</td>
<td>{item.Infos}</td>
<td>{item.Email}</td>
<ToDate value={item.Enrollmented} />
<OnCRUD 
    onEdit={()=> Action({show:true, id:item.Id, crud:3}) } 
    onInfo={()=> Action({show:true, id:item.Id, crud:2}) } 
    /*onDel ={()=> onDelete(item.Id)}*/ 
/>
            </tr>
            )
    }
</tbody>
</Table>
</div>

{action.show ?
        <MemberModal
        global={global}
        id={action.id}
        mode={action.crud}
        show={true}
        onHide={onClose}
        />
: 
<>
{ action?.id > 0 ?
    <MemberTeams global={global} member={action.id}  />  
: 
null }
</>
}

</Container>
        )
}