import React, { useState, useEffect } from 'react'
import './Team.css'
import {Table} from 'react-bootstrap'
import {MemberModal} from '../Member/MemberModal';
import {OnAdd, OnCRUD} from '../Shared/ActionControl'
import {ToDate,ToId} from '../Shared/ViewControl'
import {Get} from '../Api';


export const TeamDetail = ({global, team, Message}) => {
    const [loaded, Load] = useState(false)
    const [items, Items] = useState([])
    const [values, setValues] = useState({mode:0, id:0, showing:false});

    useEffect(() => {
        Items([])
        if(team>0) {
            Get(`Team/Detail/${team}`, Items, Load, Message)
        }
        //console.log('TeamDetail.onLoad', team, items);  //alert(request)
    }, [team, loaded])
 
        return (  
<>
{ loaded ?
    <div className="FullScreen">
<Table striped hover bordered size="sm">
<thead>
    <tr>
        <th>رقم</th>
        <th>الإسم</th>
        <th>الهاتف</th>
        <th>البلد</th>
        <th>الدور</th>
        <th>تقديم</th>
        <th>البريد</th>
        <th>تاريخ</th>
        <OnAdd onClick={()=>setValues({showing:true, id:0, mode:2})}/>
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }}>
    {
        items.map(item=>
            <tr key={item.Id}>

<ToId value={item.Id} onClick={()=> setValues({showing:true, id:item.Id, mode:0})} />
<td>{item.Name}</td>
<td>{item.Phone}</td>
<td>{item.Country}</td>
<td>{item.Role}</td>
<td>{item.Infos}</td>
<td>{item.Email}</td>
<ToDate value={item.Enrollmented} />

<OnCRUD 
    onEdit={()=> setValues({showing:true, id:item.Id, mode:1})} 
    onInfo={()=> setValues({showing:true, id:item.Id, mode:0})} 
/>
            </tr>
            )
    }
</tbody>
</Table>

{values.showing ?
        <MemberModal
        global={global}
        id={values.id}
        mode={values.mode}
        show={true}
        onHide={()=>setValues({showing:false, id:0, mode:0})}
        />
: null}

</div>
: <h6>TeamDetail loading...</h6> }
</>
        )
}
