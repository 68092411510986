/* MeR 240925 0512
*/

import React, { useState, useEffect, useLayoutEffect } from 'react'
import '../Managing/Managing.css'
import './Evaluation.css'
import {ToNotify} from 'fast_inon'

import {Focused} from '../Timer/Timers'

import {meSplitter} from '../Shared/meGlobal'

import EvalTree from "./EvalTree"
import EvalView from "./EvalView"
import {Get} from '../Api'

const Evaluation = ({team, layout, onEtat, search}) => {

    const entity='Evaluation'
    const activity=19

    const [message, Message] = useState('')
    const [node, Node] = useState({request:'', entity:entity, level:'', id:0})
    const [cell, Cell] = useState({request:'', entity:'', id:0, field:'', value:''})

    const [options, Options]= useState([])
    const [loaded, Load] = useState(false)

    //.filter(o => o.Parent===1)
  
    const splitter = new meSplitter(`${entity}Side`,`${entity}Container`,layout)
    useLayoutEffect (()=>{  
        //alert(node.entity)
        splitter.selectorAll() 
    })

    useEffect(()=>{
        //alert(`${team} node=${node.request} - cell=${cell.request}`)
        if(options.length===0) {
            Get("/Evaluation/Options", Options,null,Message)
            Load(true)
        }
        else {
            let etat = `${options.length} options !`
            onEtat && onEtat(etat)
        }
    },[team, loaded])

    const onNode = (item) => {
       
        if(item.level.endsWith('4')) {
            //alert(`level=${item.level}, id=${item.id}`)
        }

        Node({...item, entity:entity, request:`/${entity}/${item.id}/${item.level}`})
      }
    
      const onCell = (item) => {
        //console.log(cell)
        //alert(`${item.entity}-Cell : ${item.field}-${item.id}=${item.value}`)
        Cell({...item, entity:`${entity}-${item.id}`, request:`/${entity}/${item.id}/${node.level}_`})
      }
        
      Focused(1, activity)

return (
    layout ?
    <div className="Sides" id={`${entity}Container`} 
        onMouseDown={(e)=>{splitter.mouseDown(e)}} 
        onMouseMove={(e)=>{splitter.mouseMove(e)}} 
        onMouseUp={(e)=>{splitter.mouseUp(e)}}
        >
        <div className="side vside" id={`${entity}SideDetail`} style={{width:'75%'}}>
            <div className="side hside" id={`${entity}SubSideNode`} style={{height: '50%'}}>
                <EvalView parent={node} options={options} Message={Message}
                onChange={onCell} 
                />
            </div>
            <div className="side hside" id={`${entity}SubSideCell`} style={{height: '50%'}}>
                <EvalView parent={cell} options={options} Message={Message} 
                //onChange={onCell} 
                />
            </div>
        </div>
        
        <div className="side vside" id={`${entity}SideTree`} style={{width:'25%', bottom:0, verticalAlign:'bottom'}}>
            <EvalTree entity={entity} team={team} onChange={onNode} Message={Message} />
        </div>

<ToNotify 
    message={message}
    onClose={()=>{Message('')}}
/>
    </div>
    :
    null
)
}

export default Evaluation
