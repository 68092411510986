import React,{Component} from 'react'
import {Modal, Col, Form, Collapse, Button} from 'react-bootstrap'
import {CountryControl} from '../Shared/CountryControl'
import {ToNotify} from '../Shared/ViewControl'
import {SpecialInfos} from '../Member/MemberControl'
import {InText, InDetail} from '../Shared/InputControl'
import {OnSubmit} from '../Shared/ActionControl'
import { Service } from '../Service/Services'
import Api, {Exists} from '../Api'
import {sign} from '../Config/ar'

export class SignUp extends Component{

    constructor(props){
        super(props)

        this.state = {snackbarmsg:'', status:'',open:false,code:'',
        name:false, phone:false, time:'', confirmed:'', message:'', telegram:false,linked:false}
        this.onSubmit = this.onSubmit.bind(this)

        this.device = {code:'',country:'',phone:'' }
        this.risala = "فضلا انسخي الرقم الظاهر 111111 على صفحة تلغرام التي ستفتح لك بعد الضغط على المساحة الخضراء اسفله"
        this.submit = 'إرسال طلب التسجيل'
    }

    static device

    componentDidMount() {
        this.interval = setInterval(() => {
            this.onCheckLinked()
            this.setState({ time: Date.now() })
        
        }, 5000)

        // MeR 240119 disable back button in react...
        window.dispatchEvent(new CustomEvent("navigationhandler"))
      }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    snackbarClose = (event) => {
        this.setState({snackbarmsg:''});
    }

    onOpen = (event) => {
        //Collapse
        this.setState({open:!this.state.open});
    }

    onCheckLinked = () => {        
        if(this.state.confirmed.length===0 ) {
            //console.log('linked.device: ',this.device, this.state.time)

            if(this.device.code>=100000 && this.device.phone.length>=9 ) {
                Api.post('/setting/linked', this.device)
                .then(response=>{
                    //console.log('linked.response: ',response.data)
                    if(response.data?.id>0) {
                        this.setState({confirmed:response.data.message})
                    }
                    this.setState({snackbarmsg:response.data.message}); 
                })
                .catch(error => {
                    this.setState({message:error.message})        
                    //window.location.reload()
                })
            }
        }
    }

    onSubmit(event) {
        event.preventDefault()
        let item = {
            app: Api.defaults.domain,
            name: event.target.Name.value,
            country: event.target.CountryId.value,
            phone: event.target.Phone.value,
            email: event.target.Email.value,
            title: event.target.Title.value,
            infos: event.target.Infos.value,
            age: event.target.AgeId.value,
            gender: event.target.GenderId.value,
            schooling: event.target.SchoolingId.value,
            recitation: event.target.RecitationId.value,
            memorization: event.target.MemorizationId.value
        }

        console.log('signup item:',item)
        Api.post('/signup', item)
        .then(response=>{
          //console.log('signup response:', response.data)
          this.setState({snackbarmsg:response.data.message, status:'مرحبا'})
  
          if(response?.data?.message && response.data.message.includes("Salaam")) {   
            this.setState({code:response.data.code})
            this.device = {                
                phone:item.phone,
                country:item.country,
                code:response.data.code
            }
            //console.log('/Sign: ',this.device)
          }
        })
        .catch(error => {        
          //console.log('signup error:',error)
          this.setState({snackbarmsg:error.message}); 
      })
    }

    onChangePhone = (e) => {
        this.setState({phone:false})

        const phone=e.target.value.trim()
        if(phone.length>0)
        {
            if(phone.startsWith('0')) {
                const phoneRegex = /^\d+$/
                if (phone.length>9 && phoneRegex.test(phone)) {
                    this.setState({phone:true, message:''})  
                    
                    Exists('Phone',phone)
                    .then(status => {
                        if(status===200)
                            this.setState({phone:false, message:'رقم الهاتف  موحود مسبقا'})    
                    })
                    .catch(error => {
                        //console.error(error)
                    })
                    
                }
                else {
                    this.setState({message:"رقم الهاتف غير صحيح"})
                }
            }
            else {
                this.setState({message:"يجب أن يبدأ رقم الهاتف بـ 0"})
            }

        }
    }

    onChangeName = (e) => {
        this.setState({name:false})

        const name=e.target.value.trim()
        if(name.length>0)
        {
            const arabicRegex = new RegExp("^[\u0621-\u064A]+ ?[\u0621-\u064A]+ ?[\u0621-\u064A]+$")

            if (name.length>2 && arabicRegex.test(name)) {
                this.setState({name:true, message:''})
            }
            else {
                this.setState({message:"ينبغي أن يكون الاسم عربياً "})
            }
        }
    }

    onRadio = (e) => {
        this.setState({
          telegram: (e.target.value==='Yes')
        })
      }

    onOption = (e) => {
        e.preventDefault()
        //Item({...item, [e.target.name]: e.target.value})            
        //console.log('Modal onChange', e.target.name, e.target.value)
    }

    render() {

        return (

    <div className="outlet">

<ToNotify 
message={this.state.snackbarmsg}
onClose={this.snackbarClose}
/>

            <Modal.Body>

{ this.props.global.connected() ?

<Col sm={8} >
    
<h1>{this.state.confirmed} مرحبا</h1>

</Col>
:

 <>
     <h2 style={{ display: 'flex', justifyContent: 'flex-end', color:'darkgreen', margin:'1rem' }}>{sign.title}</h2>
     <Col sm={8} >

{ this.state.code.length===6 
?
    <Service style={{left:'10%', width:'80%', height:'100%'}} 
    icon="images/telegram.png" 
    info={this.risala.replace('111111',this.state.code)} 
    plan={this.state.code} 
    start={"Telegram/AkademyMaBot <- " + "اذهب إلى"}
    to="https://telegram.me/AkademyMaBot"
    />
:    

<>

<div className={this.state.telegram?'BasicInfos degrade-blue':'BasicInfos degrade-red'}>
        <h3 style={{ display: 'flex', justifyContent: 'flex-end', color:'darkblue', margin:'0.5rem' }}>{sign.telegram.verification}</h3>
        <label className='col-sm-4 text-right h3'>
            <input type="radio" value="Yes" checked={this.state.telegram} onChange={this.onRadio} />
             نعم
        </label>

        <label className='col-sm-4 text-right h3'>
            <input type="radio" value="No" checked={!this.state.telegram} onChange={this.onRadio} />
             لا
        </label>
</div>

{ this.state.telegram ?
    <Form onSubmit={this.onSubmit}>


         <div className={this.state.phone?"BasicInfos degrade-green":"BasicInfos degrade-gray"}
            style={{border:'12px solid darkgreen', borderColor:(this.state.phone)?'darkgreen':'darkred', padding:"2rem"}}>         
             <h3 style={{color:"darkgreen", textAlign:"center"}}>{(this.state.name && this.state.phone) ? this.state.confirmed : 'الرجاء إدخال الاسم ورقم الهاتف'}</h3>

            <InText name="Name" label="الأسم الثلاثي (بالعربية)" onChange={this.onChangeName} required/>
            <CountryControl apiUrl={this.props.global.apiUrl} label='البلد'/> 
            <InText name="Phone" required label="رقم الهاتف" onChange={this.onChangePhone} />
            
            <h4 style={{color:"darkred", margin:"1rem"}}>{this.state.message}</h4>
        </div>

        <SpecialInfos onOption={this.onOption} />

<div style={{border:"12px solid darkgray", borderRadius:"15px", justifyContent:"center", marginTop:'2rem'}}>         
    <Button onClick={this.onOpen} style={{backgroundColor:"darkgray", borderColor:"gray", textAlign:'right', width:'100%'}}>
    ... معلومات اختيارية
    </Button>   
        <Collapse in={this.state.open}>
            <div style={{padding:"2rem"}}>
            <InText name="Email" type="email" label="بريد إلكتروني" />
            <InText name="Title" label="لقب"/>
            <InDetail name="Infos" label={sign.label.infos}/>
            </div>
        </Collapse>
</div>

<OnSubmit label={this.submit} crud={(this.state.name && this.state.phone)?1:0} />

         </Form>
:
<div className='BasicInfos degrade-gold  text-right' style={{color:'darkred', padding:'0.5rem'}}>
<h2>يعتمد هذا التطبيق كليا على تلغرام المثبت على هاتفك الذكي</h2>
<h1> يرجى إنشاء حساب تلغرام قبل المتابعة </h1>
<h2>...</h2>
</div>
}
</>
            }
     </Col>
 </>
 
    }
            </Modal.Body>
 
            </div>
        )
    }
}

