import React, { useState, useLayoutEffect, useEffect } from 'react'
import '../Memorizing/Memorizing.css'
import {ToNotify} from '../Shared/ViewControl'
import MeditatingControl from './MeditatingControl'
import Api, {Cookie} from '../Api'
import { Timers, Focused } from '../Timer/Timers'
import TreeView from "../meTree/TreeView"
import {meSplitter} from '../Shared/meGlobal'
import {PostItLayer} from '../PostIt/PostIt'

const Meditating = ({search, onEtat, role, layout}) => {
    
    const entity='Meditating'
    const [page, Page] = useState(Cookie('meSafha')??1)
    const [reported, Reported] = useState(6)
    const [message, Message] = useState('')
 
    const splitter = new meSplitter("Side", `${entity}Container`)

    useLayoutEffect (()=>{  // like useEffect but synchronously after all DOM
        onEtat(`splitter ${splitter.items.length}`)
        splitter.selectorAll()

    },[])


    const onNode = (selected) => {
        Page(selected[0])
    }

    Focused(page, 2, Reported )
        
    const onPrev = () => {
        if(page>1) {
            Page(page-1)
        }
    }

    const onNext =() => {
        if(page<485) {
            Page(page+1)
        }
    }

    const onPage = (event) => {
        let safha = event.target.value
        //console.log('Memorizing.onPage', event); alert(safha)
        Page(safha)
    }

    useEffect(()=>{
        document.cookie = `meSafha=${page}`
    },[page])

    const onMouseUp = (event) => {
        splitter.mouseUp(event, Api)
    }
                    

return (
                
<>

{ layout && page>0 ?
        <div className="Sides" id={`${entity}Container`}
            onMouseDown={(e)=>{splitter.mouseDown(e)}} 
            onMouseMove={(e)=>{splitter.mouseMove(e)}} 
            onMouseUp={onMouseUp}
        >

            <PostItLayer entity={'Meditating'} element={page} role={role} search={search} onEtat={onEtat} />

            <div className="side vside" id="SideTimer" style={{width: '25%'}}>
                <Timers element={page} onElement={onPage} reported={reported}/>
            </div>

            <div className="side vside" id="SideDetail" style={{width: '50%'}}>
                <MeditatingControl role={role} page={page} onNext={onNext} onPrev={onPrev} Message={Message} />
            </div>

            <div className="side vside" id="SideTree" style={{width: '25%', bottom:0, verticalAlign:'bottom'}}>
                <TreeView onChange={onNode} page={page} />
            </div>

        </div>
: 
    <MeditatingControl role={role} page={page} onNext={onNext} onPrev={onPrev} Message={Message} />
}

<ToNotify message={message} onClose={()=>Message('')} />
</>
     )
}

export default Meditating
