import React,{Component} from 'react';
import {Form, Row, Col} from 'react-bootstrap';
import {CountryControl} from '../Shared/CountryControl';
import {RoleControl} from '../Role/RoleControl';

export class MemberForm extends Component {

    
    constructor(props){
        super(props)

        this.state = {Member : this.props.Member }

        this.mode = this.props.mode ?? 0
        
        //console.log('Member',this.props.Member)
    }

    render() {
      
        return(
      <div>
          {
this.state.Member ?
    <div className="container">
            <Form.Group>
                <Form.Control
                type="text"
                name="Name"
                defaultValue={this.state.Member.Name}
                
                readOnly={this.mode===0}
                required
                placeholder="الأسم الثلاثي"
                />

              <Row>
                <Col sm={5}>
             <CountryControl apiUrl={this.props.global.apiUrl} itemId={this.state.Member.CountryId} readOnly={this.mode===0} label='البلد'/> 
             </Col>
             <Col>
                <Form.Control style={{marginTop:'1rem'}}
                type="text"
                name="Phone"
                defaultValue={this.state.Member.Phone}
                readOnly={this.mode===0}
                required
                placeholder="رقم الهاتف"
                />
             </Col>  
             </Row>

             <RoleControl global={this.props.global} id={this.state.Member.RoleId} mode={this.mode} label='الدور'/> 
             </Form.Group>             

             <Form.Group>
               <Form.Label />
                <Form.Control
                type="text"
                name="Email"
                defaultValue={this.state.Member.Email}
                readOnly={this.mode===0}
                placeholder="البريد الإلكتروني"
                />
                <Form.Label />
                <Form.Control
                type="text"
                name="Title"
                defaultValue={this.state.Member.Title}
                readOnly={this.mode===0}
                placeholder="اللقب"
                />
                <Form.Label />
                <Form.Control
                type="text"
                name="Infos"
                defaultValue={this.state.Member.Infos}
                readOnly={this.mode===0}
                placeholder="الملف الشخصي"
                />
            </Form.Group>   

            <Form.Group>
            <Form.Label style={{ display: 'flex', justifyContent: 'flex-end' }}>تاريخ الميلاد</Form.Label>
              <Form.Control
                type="date"
                name="Birthed"
                placeholder="تاريخ الميلاد"
                defaultValue={this.props.global.toString(this.state.Member.Birthed)}
                readOnly={this.mode===0}
                onChange={(e) => {
                  this.setState({...this.state.Member, Birthed:e.target.value})
                }}
              />
            </Form.Group>

            <Form.Group>
            <Form.Label style={{ display: 'flex', justifyContent: 'flex-end' }}>تاريخ التسجيل</Form.Label>
              <Form.Control
                type="date"
                name="Enrollmented"
                placeholder="تاريخ التسجيل"
                defaultValue={this.props.global.toString(this.state.Member.Enrollmented)}
                readOnly={this.mode===0}
                onChange={(e) => {
                  this.setState({...this.state.Member, Enrollmented:e.target.value})
                }}
              />
            </Form.Group> 
            </div>  
:  <div></div>  
              }  
              </div>          
        );
    }
}
