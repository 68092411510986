import React, { useLayoutEffect } from 'react'
import {Notes} from '../Note/Notes'
import { Timers } from '../Timer/Timers'
import {meSplitter} from '../Shared/meGlobal'

const Activities = ({activity, element, onElement, reported, search}) => {
    const entity='Loging'
    const detail = new meSplitter(`${entity}Side`,`${entity}Container`, false)

    useLayoutEffect (()=>{  
            detail.selectorAll()       
        },[])
    

return (
    <div className="Sides" style={{display:'block' ,maxHeight:'100%'}} id={`${entity}Container`}
            onMouseDown={(e)=>{detail.mouseDown(e)}} 
            onMouseMove={(e)=>{detail.mouseMove(e)}} 
            onMouseUp={(e)=>{detail.mouseUp(e)}}
        >

        <div className="side hside" id={`${entity}SideNote`} style={{height: '50%'}}>
            <Notes activity={activity} search={search} element={element} onNote={onElement}  />
        </div>
        <div className="side hside" id={`${entity}SideTimer`} style={{height: '50%'}}>
            <Timers element={element} activity={activity} reported={reported}/>
        </div>
    </div>


     )
}

export default Activities
