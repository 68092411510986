import React, { useState, useEffect } from 'react'
//import './Setting.css'
import {Collapse, Button} from 'react-bootstrap'
import {ToNotify} from '../Shared/ViewControl'
import {Activity} from '../Activity/Activity'
import {CourseDetail} from '../Course/CourseDetail'
import {Teams} from '../Team/Teams'
import {Focused} from '../Timer/Timers'
import Api from '../Api'
import { Lessons } from '../Lesson/Lessons'
import { ClasseDetail } from '../Classe/ClasseDetail'

const Settings = ({global, search, role, Etat}) => {
    const [message, Message] = useState('')

    const [loaded, Load] = useState(false)
    const [items, Items] = useState([])

    const [entities, Entities]= useState({Team:false, Course:false, Lesson:false, Classe:false, Activity:false, Role: false})

        const onCollapse = (e) => {

            Entities({...entities, [e.target.name]: !entities[e.target.name]})
            //console.log("setEntity", entities); alert(entities.Activity)
        }

        Focused(1001, 5 )

        useEffect(() => {

            Api.get(`/Setting`)
                .then(response => {
                    //console.log('Setting',response.data)
                    Items(response.data)
                    Load(true)
                    Message(response)
                })
                .catch(error => {
                    //console.log('Setting error',error)
                    Message(error)
                    console.error(error)
                })
            
            sessionStorage.setItem("routeCurrent","/setting")

        }, [loaded])
    
//let _margin="'3% 5% 2% 5%'"

return (
<>
<ToNotify 
message={message}
onClose={()=>{Message('')}}
/>

{ global.connected() ?

<div style={{position:"relative", width:"inherit"}}>          

    <div style={{border:"12px solid green", borderRadius:"15px", justifyContent:"center", margin:'1% 5% 2% 5%', width:'90%'}}>         
        <Button name="Team" onClick={onCollapse} 
        style={{backgroundColor:"green", borderColor:"gold", textAlign:'right', width:'100%'}}>
        المجموعات 
        </Button>   
        <Collapse in={entities.Team}>
            <div>
                <Teams global={global} search={search} role={role} Etat={Etat} />
            </div>
        </Collapse>
    </div>

    <div style={{border:"12px solid green", borderRadius:"15px", justifyContent:"center",  margin:'1% 5% 2% 5%', width:'90%'}}>         
        <Button name="Course" onClick={onCollapse} 
        style={{backgroundColor:"green", borderColor:"gold", textAlign:'right', width:'100%'}}>
        الدورات 
        </Button>   
        <Collapse in={entities.Course}>
            <div>
                <CourseDetail global={global} search={search} />
            </div>
        </Collapse>
    </div> 

    <div style={{border:"12px solid green", borderRadius:"15px", justifyContent:"center",  margin:'1% 5% 2% 5%', width:'90%'}}>         
        <Button name="Lesson" onClick={onCollapse} 
        style={{backgroundColor:"green", borderColor:"gold", textAlign:'right', width:'100%'}}>
        الدروس 
        </Button>   
        <Collapse in={entities.Lesson}>
            <div>
                <Lessons global={global} search={search} />
            </div>
        </Collapse>
    </div> 

    <div style={{border:"12px solid green", borderRadius:"15px", justifyContent:"center",  margin:'1% 5% 2% 5%', width:'90%'}}>         
        <Button name="Classe" onClick={onCollapse} 
        style={{backgroundColor:"green", borderColor:"gold", textAlign:'right', width:'100%'}}>
        الأقسام 
        </Button>   
        <Collapse in={entities.Classe}>
        <div style={{padding:"0.5rem"}}>
            <ClasseDetail global={global} />
        </div>
        </Collapse>
    </div> 

    <div style={{border:"12px solid green", borderRadius:"15px", justifyContent:"center", margin:'1% 5% 2% 5%', width:'90%'}}>         
        <Button name="Activity" onClick={onCollapse} 
        style={{backgroundColor:"green", borderColor:"gold", textAlign:'right', width:'100%'}}>
        الأنشطة 
        </Button>   
        <Collapse in={entities.Activity}>
            <div style={{padding:"0.5rem"}}>
                <Activity global={global} search={search} />
            </div>
        </Collapse>
    </div>
</div>             

: <h5>Not Connected</h5> }

</>
        )
}

export  {Settings}
