import React,{useState, useEffect} from 'react'
import {Modal,Button,Row,Col,Form} from 'react-bootstrap'
import {InText, InDetail} from '../Shared/InputControl'
import {RoleMembers} from '../Role/RoleMembers'
import { addDays } from '../Shared/Formating'
import {ModalHeader, ModalFooter} from '../Shared/ModalControl'
import {ToNotify} from '../Shared/ViewControl'
import OptionsControl from '../Shared/OptionsControl'
import { OnSubmit } from '../Shared/ActionControl'
import PeriodControl from '../Shared/PeriodControl'
import {Fetch} from '../Api'

/**
 * MeR 221024
 * action : {show:false, id:0, crud:0}
 * 
 */
const ClasseModal = ({global, action, onHide, Fetched}) => {
  const entity = "Classe"

  const readOnly = (action.crud ?? 2) === 2
  //alert(readOnly)

  const [loaded, Load] = useState(false)
  const [item, Item]= useState({Id:0, Master:2, CourseId:1, LessonId:1, Description:'',Starts:addDays(0), Ends:addDays(6)})
  const [notify, Notify] = useState({open:false, message:''})

      useEffect(() => {
        if(action.id>0) {
            global.GetItem(entity, action.id)
            .then(data => {
                Item(data)
                Load(true)      
            })
            .catch(error => {
              Notify({open:true, message:error})
              console.log({ error })
            })
        }
        else if(action.crud===1)
        {
          Load(true)
        }
    }, [loaded])
 
            
    const onChange = e => {
        e.preventDefault()
        Item({...item, [e.target.name]: e.target.value})
        //console.log('ClasseModal onChange',item)
      }

    const onFetch = (e) => {
        Fetch(entity, item, action.crud, Load)
        Fetched(true)
    }

    
        return(
          <>
<ToNotify
message={notify.message}
onClose={()=>Notify({open:false, message:''})}
/>   
        <Modal
              show={action.show}
              onHide={onHide}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
        >

<ModalHeader title={entity} id={action.id} message={notify.message} />

            <Modal.Body>

     <Col sm={11} >
         { loaded ? 
         <Form onSubmit={onFetch}>

<OptionsControl entity={'Course'} id={item.CourseId} onChange={onChange} readOnly={readOnly} label='الدورات'/> 
<OptionsControl entity={'Lesson'} id={item.LessonId} onChange={onChange} readOnly={readOnly} label='الدروس'/> 
<InText name="Title" value={item?.Title ?? ''} onChange={onChange} readOnly={readOnly} label={"العنوان"} required />

<RoleMembers role="Master" entityId={action.id} global={global} id={item.Master}  label='معلمة' readOnly={readOnly} onChange={onChange} />
<RoleMembers role="Inspector" entityId={action.id} global={global} id={item.Inspector}  label='مفتشة' readOnly={readOnly} onChange={onChange} />

<PeriodControl item={item}  onChange={onChange} readOnly={readOnly} />
<InDetail name="Content" value={item?.Content ?? ''} onChange={onChange} readOnly={readOnly} label={"ملاحظات"} />

<OnSubmit crud={action.crud} />

         </Form>
        : <div>{entity} is loading...</div>}
     </Col>

            </Modal.Body>
            
            <ModalFooter onHide={onHide} />

          </Modal>
</>
  )
}

export default ClasseModal

