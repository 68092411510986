import React,{useState, useEffect, useCallback} from 'react'
import {Col, Row, Button} from 'react-bootstrap'
import './Notes.css'
import {ToNotify} from '../Shared/ViewControl'
import {OnAction} from '../Shared/ActionControl'
import { viewDate, activityStyle, toText, isEditable } from '../Shared/Formating'
import {Comments} from '../Note/Comments'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import {Fetch} from '../Api'

const Notes = ({activity=1, element, search, onNote}) => {
    const entity='Note'

    const [items, Items] = useState([]);
    const [selected, Select] = useState(0);
    const [loaded, Load] = useState(false);
    const [notify, Notify] = useState('')

    useEffect(() => {
        if(element>0) {
            Fetch(`${entity}/${element}`, null, 2, Load, null, Items)
            //console.log('Notes load :', `${entity}/${element}`, items)
        }
        else
            Select(0)
    }, [loaded, element])

    const onCreate = useCallback(() => {
        let item={
            Text:'',
            ActivityId: activity,
            ElementId: element
        }
        onFetch(item, 1)
    }, [items]);

    const onClick = (id) => {
        Select(id)
    }
    
    const onUpdate=(item) => {
        onFetch(item, 3)
    }

    const onDelete=(item)=> {
        onFetch(item, 4)
    }

    const onFetch = (item,crud) => {
        //alert('onNotes')
        Fetch(entity, item, crud, Load)
    }

    useEffect(()=>{
        onNote(selected) 
    },[selected])
 
        return (
            
            <div className='Notes'>


<ToNotify 
message={notify}
onClose={()=>Notify('')}
/>     

{ loaded ?

<Row className="comments-grid">
<OnAction onCreate={()=>onCreate()}/>
    {        
        items.filter((item)=>{
            return (item.Text+item.Member+viewDate(item.updatedAt)).indexOf(search)!==-1
        })
        .map(item=>
    <Note key={`note-${item.Id}`} item={item} selected={selected} 
        onSelect={onClick} onSave={onUpdate} onDelete={onDelete} Notify={Notify} />
        )
    }
</Row>

:
<h5>{entity}(s) loading...</h5>
}
</div>
        )
}


const Note = ({item, onSelect, selected, Notify, onSave, onDelete, search, note=true, fstyle=null}) => {

    const [editing, Edit] = useState({Id:0, Text:''});
    const [editable, Editable]=useState(false)
    const [comment, Comment]=useState(false)

    //console.log('<Note...', item)

    let cstyle= {...activityStyle(item?.Activity), ...(fstyle??{opacity:1, border:0}) }

    const onChange = (text) => {
        if(editing.Text!==text) {
            Edit({Id:item.Id, Text:text})         
        }
    }

    const onClick = (item) => {
        onSelect(item.Id)
    }

    const onEdit = (item) => {
        Edit({Id:item.Id, Text:item.Text})
        //console.log('Comments.onEdit', editing.Text, item.Text)
    }

    const onCancel=()=> {
        //Edit({Id:0,Text:''})  
        onSelect(0)  
    }

    useEffect(()=>{
        Editable(isEditable(item.updatedAt))
        if(selected===0)
        {
            Edit({Id:0, Text:item.Text})
        }
        //console.log('Notes.useEffect', selected, editable, editing)
    },[selected, editable])


    const onInfo = note ? {title:`${item.Comments} تعليقات`, onClick:()=>Comment(!comment)} : null

    return (
        <Col className="comments-cell" style={cstyle} >

        <Row className="align-items-end" onClick={()=>onClick(item)}>
        <Col sm={3} style={{fontWeight: 'bold', color:'silver' }} >{item.Name}</Col>
        <Col sm={3} style={{fontWeight: 'bold', color:'gold' }} >{item.Comments}</Col>
        <Col sm={3} style={{color:'silver' }} >{item?.Activity}</Col>
        <Col sm={3} style={{fontSize: '0.7rem', color:'cyan', direction:'ltr'}}>{viewDate(item.updatedAt)}</Col>
        </Row>
        
        { item.Id===selected ?
<>

            { editing?.Id>0 ?
            <>
<Row style={{ margin: '0.3rem', color:'blue', overflowY: 'scroll', backgroundColor:'white', zoom:'110%' }}>
<ReactQuill theme="snow" value={editing.Text} onChange={onChange} style={{width:'99%'}} />
</Row>
                <OnAction onSave={()=> onSave(editing)} onCancel={onCancel} onDelete={()=>onDelete(item)} />
            </>
            :
            <>   
                <Row style={{...cstyle, color:'gold', fontSize:'1rem'}}>{toText(item.Text)}</Row>   
                { editable ?
                <OnAction onEdit={()=>onEdit(item)} onInfo={onInfo} />  
                : null }
            </>
            }
</>
        :
        <>
            <Row style={cstyle}  onClick={()=>onClick(item)}>{toText(item.Text)}</Row>

            {note ? <OnAction onInfo={onInfo} /> :null}
        </>
        }

        {comment ?
            <Comments note={item.Id} search={search} Message={Notify} selected={selected} onSelect={onSelect} />
        :null}

    </Col> 
    )
}

export {Note, Notes } 
