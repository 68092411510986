import axios from 'axios';

const path = "https://moshaf.akademy.ma/waqafaat/"
const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiaXNtaSI6Itio2ZDYs9uh2YXZkCDZsdmE2YTZh9mQINmx2YTYsdmR2Y7Yrduh2YXZjtmA2bDZhtmQINmx2YTYsdmR2Y7YrdmQ2YrZhdmQIiwiYWxhYW4iOiIyNC8wNy8yMDI0IC0g2KfZhNij2LHYqNi52KfYoSAxOCDZhdit2LHZhSAxNDQ2IiwiaWF0IjoxNzI2ODQyNjIzfQ.svD2I5wZTnz0J65-TWcAbY5JGfH76_jFPVbyaTAE7n4"

const Api = axios.create({

  baseURL: `https://api.akademy.ma`,   

  headers : {
    'Authorization': `Bearer ${token}`,       
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

 const onGet = (request, Items, Load, Message) => {
  Items([])
  Api.get(request)
  .then(response => {
      //console.log('Moshaf.Get', request, response.data); alert('Moshaf.Load '+request)
      Items(response?.data)
      Load && Load(true)
  })
  .catch(error => {
      //console.log('Error',request,error) //; alert(request)
      Message && Message(error)
  })
}

const onPut = (id, field, value, Message) => {

  if(id && id>0 && field && value) {
    let query=`/waqafaat/${id}/${field}/${value}`
    //alert(query)
    Api.put(query)
    .then(response => {
      let rows=response.data.affectedRows
      Message && Message(`${rows} updated`)
      //console.log('Moshaf.onUpdate', response.status, response.data.affectedRows)
    })
    .catch(error => {
      Message && Message(error)
      console.error(error)
    })
  }
}


const getPage = (page, type='jpg') => {
  let name = (page||0).toString().padStart(3,'0')+'.'+type
  //setCache(path+name)
  return path+name
}

const getPart = (item, type='mp3') => {
  if(item){
    let name = `${item.Soora.toString().padStart(3,'0')}/${item.Sah}.${type}`
    //setCache(path+name)
    return path+name
  }
}

const getFile = (soora, safha, type='mp3') => {
  let name = `${soora.toString().padStart(3,'0')}/${safha.toString().padStart(3,'0')}.${type}`
  //setCache(path+name)
  return path+name
}

const setCache = (url) => {
  if ('caches' in window) {
    caches.open('CacheAkademy').then(cache => {
      //alert(url)
      cache.add(url)
      console.log(cache)
    });
  }
}

const transMin = (min, rect, pane) => {
  if(min.includes('px'))
      return min
  if(min.includes(',')) {
      
      if(rect?.right>0 && rect?.bottom>0) {

          let w=parseInt(rect.right-rect.left)
          let h=parseInt(rect.bottom-rect.top)

          let xy = min.split(',')
          let top = parseInt(xy[0]*h/100)+'px'
          let right = parseInt(xy[1]*w/100 + (pane.right-rect.right))+'px'
          return {top, right}
      }
  }
}

const transMins = (items, rect, pane) => {
  return new Promise(function (resolve, reject) { 
    if(items?.length>0) {
        let step = Math.round(100/(items.length/2+1))

        //console.log('Step',step,items.length)
        for(let p=0; p<items.length; p++) { 
            items[p].Locked = 0

            let min = items[p].Min ?? ''
            if(min?.length>0 && min.includes(',') && !min.includes('px')) {
                items[p].Min = transMin(min,rect,pane)
            }
            else {
                let t = 2 + Math.trunc(p/2) * step
                let r = 2 + 50 * (p%2)

                min = `${t},${r}`
                items[p].Min = transMin(min,rect,pane)
            }
            //console.log(items[p].Min) // MeR 0920 Bug when starting 
            if(p+1===items.length) {
                resolve(items)
            }
        }
    }
  })}

export {Api, onGet, onPut, getPage, getPart, getFile, transMin, transMins}
