import React, { useState, useEffect } from "react"
import {Modal,Button,Form,Row,Col} from 'react-bootstrap'
import {ToNotify} from '../Shared/ViewControl'
import {InText} from '../Shared/InputControl'
import {actions} from '../Config/ar'

const EvalModal = ({item, show, onHide}) => {

  const [valid, Valid] = useState(false)
  const [submitting, Submitting] = useState(false)
  const [data, setData]= useState({aaya:1, soora:2, hizb:1, thomon:1 })
  const [message, Message] = useState('')

useEffect(() => {
    //console.log('mode and selected', mode, selected)

  }, [item])  


  const onChangeData = e => {
    e.preventDefault()
    setData({...data, [e.target.name]: e.target.value})
    //console.log("data:",data)
 }

const copyText = (text) => {
  if(navigator.clipboard)
    navigator.clipboard.writeText(text)
}


const onAaya = e => {
  Valid(false)
  if(e.target?.value?.length===3)
  {
    let old=data
    let input=e.target.value
    onChangeData(e)

      const numRegex = /^\d+$/;
      if (numRegex.test(input)) {

        //alert(e.target.value +" : "+ old.aaya)
        //console.log('onAaya', old, data)
        if( input < old.aaya || input===0 )
        {
          var soora=document.getElementById('soora')
          if(soora) {
            soora.value = (parseInt(soora.value)+1).toString()
            //document.getElementById('soora').onchange()
          
            //var event = new Event('change');
            //soora.dispatchEvent(event);
          }
        }        
        Valid(true) 
      }
  }
  else if (e.key === 'Enter') {
    Valid(true) 
    //console.log('do validate')
  }

}

const onSoora = (e) => {
  onChangeData(e)
  //alert(e.target.value)
}

const onSubmit=(e)=> {
    //console.log("submit data: ",data)
    Submitting(true)
    let request =``
}

return (

<div className="outlet">

<ToNotify 
message={message}
onClose={()=>Message('')}
/>   
   
        <Modal
          show={show}
          onHide={onHide}
          size="lg"
          aria-labelledby = "التقييم"
          animation={false}
          style={{marginTop:'-1rem', height:'100%'}}
          centered
        >
            <Modal.Body style={{ width:"100%", height:"95vh", display: 'flex', justifyContent: 'center' }}>
            
     
      <Form style={{border:"12px solid green", borderRadius:"10px", padding:"1rem", position:'absolute', top:'50%', left:'25%', width:'50%', height:'40%', opacity:'0.7'}}>
                      
              <InText name="soora" required onChange={onSoora} />
              <InText name="aaya" required onChange={onAaya} />
              <InText name="hizb" required onChange={onChangeData} />
              <InText name="thomon" required onChange={onChangeData} />

        <h5 style={{color:"orange", margin:"1rem"}}>{message}</h5>

           <Button onClick={onSubmit} size="lg" 
                    style={ valid && !submitting
                            ? {width:"50%",backgroundColor:'green',color:'yellow'} 
                            : {width:"50%",backgroundColor:'darkgray',color:'gray', pointerEvents:'none', cursor:'not-allowed'}}>
                {actions.save}
            </Button>

      </Form>

<Button className="mr-2" variant={"outline-danger"}  
        style={{ position:'absolute', width: '8%', aspectRatio:'1 / 1', top:'2%', left:'90%' }}
            onClick={onHide}
            ><i className={"fa fa-close"} style={{display: 'flex', justifyContent: 'center'}}></i>
            </Button>

 </Modal.Body>
          </Modal>


    </div>
  )
}
export default EvalModal
