import React from 'react';
import {Form} from 'react-bootstrap'
import {toDate} from './Formating'

const PeriodControl = ({item, readOnly, onChange}) => {
  //console.log('PeriodControl',item,readOnly)
  
    return (
<Form.Group controlId="PeriodDates" style={{direction:'rtl'}}>
  <Form.Label style={{ display: 'flex' }}>الفترة</Form.Label>

  <Form.Group className='form-row'>

  <Form.Control className='col-sm-6' align='right'
    type="date"
    name="Starts"
    defaultValue={toDate(item?.StartDate)}
    readOnly={readOnly}
    onChange={(e) => {
      onChange(e)
    }}
  />

  <Form.Control className='col-sm-6'  align='left'
    type="date"
    name="Ends"
    defaultValue={toDate(item?.EndDate)}
    readOnly={readOnly}
    onChange={(e) => {
      //item.EndDate=e.target.value not in the state !
      onChange(e)
    }}
  />

  </Form.Group>
</Form.Group>

    )
}

export default PeriodControl;
