import React,{Component} from 'react';
import {Modal,Button,Row,Col,Form} from 'react-bootstrap'
import {RoleMembers} from '../Role/RoleMembers'
import {ToNotify} from '../Shared/ViewControl'
import {OnSubmit} from '../Shared/ActionControl'
import {InText, InDetail} from '../Shared/InputControl'
import {ModalFooter} from '../Shared/ModalControl'

export class LessonModal extends Component {
    constructor(props){
        super(props)

        this.state = { snackbarmsg:'', Item:null, loading:false, readOnly:(this.props.mode===0) }
        this.handleSubmit = this.handleSubmit.bind(this)

        this.entity = this.props.entity ?? "Lesson"
        this.message=`${this.entity} Modal`

    }

    componentDidMount(){
        if(this.props.show && this.props.id > 0) {
            this.setState({loading:true})
            this.props.global.GetItem(this.entity,this.props.id)
            .then((data) =>  {
                    this.setState({Item:data, loading:false})
                    //console.log(this.message, this.state.Item)
                }
            )
            .catch(error => console.log({ error }))
        }
        else if(this.props.mode===2)
        {
            this.setState({Item:{Id:0, Master:1, Name:'العنوان'}})
        }
    }

    snackbarClose = (event) => {
        this.setState({snackbarmsg:''});
    }

    handleSubmit(event){
        event.preventDefault();

        fetch(`${this.props.global.apiUrl+'/'+this.entity}`,{
            method:(this.props.mode===2)?'POST':'PUT',
            headers:this.props.global.headers,
            body:JSON.stringify({
            Id:(this.props.mode===2)?0:this.state.Item.Id,
            Master:event.target.Master.value,
            Title:event.target.Title.value,
            Content:event.target.Content.value
            })
        })
        .then(response => response.json())
        .then(
            (result)=>{
                this.setState({snackbarmsg:result});
                this.props.onHide()
            },
            (error)=>{
                this.setState({snackbarmsg:error});
            }
        )
    }
    
    render() {
        let { readOnly, Item } = this.state; 

        return(
            <div className="container">

<ToNotify
message={this.state.snackbarmsg}
onClose={this.snackbarClose}
/>
            <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              {
                  this.props.mode===1 ?<h3>تحديث الدرس</h3>:<h3>{`${Item?.Id ?? 0} الدرس`}</h3>
              }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

 <Row>
    <Col sm={8} >
    {
        this.state.loading || !Item ? <div>Lesson is loading...</div> :
        <Form onSubmit={this.handleSubmit}>

            <InText name="Title" value={Item?.Title ?? ''} readOnly={this.readOnly} label={"العنوان"} required />

            <InDetail name="Content" value={Item?.Content ?? ''} readOnly={readOnly} label={"ملاحظات"} />

            <RoleMembers role="Master" global={this.props.global} id={Item.Master ?? 1} readOnly={readOnly} label='معلمة' entityId={this.props.id} entity={this.entity}/> 

            <OnSubmit {...this.props} />

         </Form>
    }
    </Col>
 </Row>

            </Modal.Body>
                <ModalFooter onHide={this.props.onHide} />
          </Modal>
          </div>  
        );
    }
}
