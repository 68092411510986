import React,{useState, useEffect} from 'react'
import {Col, Row} from 'react-bootstrap'
import {ToNotify} from '../Shared/ViewControl'
import {OnAction} from '../Shared/ActionControl'
import SearchControl from '../Shared/SearchControl'
import './Team.css'
import {Get, Fetch} from '../Api'


export const TeamMembers = ({team, role}) =>{
    const entity='TeamMembers'
    const [loaded, Load] = useState(false)
    const [data, Data] = useState({registred:[], selected:[]})
    const [selected , Selected] = useState([])
    const [unselected , Unselected] = useState([])
    const [message, Message] = useState('')

    useEffect(() => {
        onLoad()
     }, [team, loaded])

    const onLoad = () => {
        let request =  `${entity}/${team}/${role}`
        //Fetch(request, null, 2, null, Load, Data)
        Get(request, Data, Load)
        console.log('TeamMembers.onLoad', data);  //alert(request)
    }

    const onFetch = () => {

        var item={team, role, selected, unselected}
        //console.log('TeamMembers.onFetch', item);

        Fetch(entity, item, 3, Load, Message)
    }

    return (

        loaded ? 

        <div className='TeamMembers'> 
        <h3 style={{textAlign:'center'}}>{"أعضاء الفريق"} {team}</h3> 
        <Row className="align-items-center">
        <Col sm={6} >
            <MembersList data={data.registred} color={"gray"} onSelected={Selected} label={"الأعضاء المتاحين"}/>
        </Col>    
        <Col sm={6}>
            <MembersList data={data.selected}  color={"teal"} onSelected={Unselected} label={"الأعضاء المختارين"}/>
        </Col>    
        </Row>

        { selected.length>0 || unselected.length>0 ?
            <OnAction onSave={onFetch} />
        : null }

        <ToNotify message={message} onClose={()=>Message('')} />
        </div> 

        :  
        <div>Members are loading...</div>   
    )
}

const MembersList = ({data, color, onSelected, label}) => {
    const [member, Member] = useState(0)
    const [search, Search]  = useState("")
    const [selected , Selected] = useState([])

    //console.log('MembersList data', data, selected); 

    useEffect(()=>{       
        if(member>0) {
            onSelected && onSelected(selected)
            Member(0)
        }
        //console.log('MembersList selected', member, selected); 
    },[member])

    useEffect(()=>{       
        //alert(search.value)
    },[search])

    const onSelect = (id) => {
        if(selected.length>0 && selected.includes(id)){
            let i = selected.indexOf(id)
            if (i > -1) { 
                selected.splice(i, 1)
                Selected(selected)
            }
        }
        else {
            selected.push(id)
            Selected(selected)
        }
        Member(id) 
    }

    const isSelected = (id) => {
        if(selected.length>0)
            return selected.includes(id)

        return false
    }

    let filteredItems = (data && data.length>0) ? data.filter(    
        (item)=>{                
            return (item.Name).indexOf(search)!==-1;
        }            
    ) : []

    const listItems = filteredItems.map((item) =>
        <li key={"TM-"+item.Id} 
            value={item.Id} 
            title={item.Id+" "+item.Name}
            style= {{ borderColor: ( isSelected(item.Id) ? 'gold' : 'green'), backgroundColor:isSelected(item.Id) ? 'silver' : color }} 
            onClick={() => onSelect(item.Id)}
        >
            {item.Name}
        </li>
        )

    return (
        <div className='TeamMembersList'>
        <Row className="align-items-center">
        <Col sm={6} ><h6>{label}</h6></Col>    
        <Col sm={6}><SearchControl onSearch={Search} /></Col>    
        </Row>
            <ul style={{ backgroundColor:color }}>{listItems}</ul>
        </div>
    )
}
