import React,{useState, useEffect} from 'react';
import { Form, Collapse, Button } from 'react-bootstrap';
import {InOption} from '../Shared/InputControl'
import {Get} from '../Api'


const SpecialInfos = ({id=0, onOption=null, Message=null, readOnly=false}) => {
    const [open, setOpen]  = useState(false)
    const [loaded, Load] = useState(false)
    const [member, Member]  = useState({Age:21,Gender:4,Schooling:6,Recitation:11,Memorization:23})

    useEffect(() => {
        if(id>0 && !loaded) {
            let request =  `Member/Detail/${id}/Options`
            //console.log('SpecialInfos',request); alert(request)
            Get(request, Member, Load, Message)
        }
     }, [loaded])       

    const onOpen = (e) => {
        setOpen(!open);

        //alert(member.Recitation)
    }

    const onSelect = (e) => {
        e.preventDefault()
        onOption && onOption(e)      
    }

    return (
<div style={{border:"12px solid darkgoldenrod", borderRadius:"15px", justifyContent:"center", marginTop:'2rem'}}>         
    <Button onClick={onOpen} style={{backgroundColor:"darkgoldenrod", borderColor:"gold", textAlign:'right', width:'100%'}}>
    ... معلومات خاصة
    </Button>   
        <Collapse in={open}>
            <div style={{padding:"2rem"}}>
            <InOption member={id} entity={'Age'} id={member.Age} onChange={onSelect} label='السن' readOnly={readOnly} /> 
            <InOption member={id} entity={'Gender'} id={member.Gender} onChange={onSelect} label='الجنس' readOnly={readOnly}/> 
            <InOption member={id} entity={'Schooling'} id={member.Schooling} onChange={onSelect} label='مستوى التعليم' readOnly={readOnly}/> 
            <InOption member={id} entity={'Recitation'} id={member.Recitation} onChange={onSelect} label='الرواية' readOnly={readOnly}/> 
            <InOption member={id} entity={'Memorization'} id={member.Memorization} onChange={onSelect} label='اختيار مقدار الحفظ' readOnly={readOnly}/> 
            </div>
        </Collapse>
</div>
    )
}

const MemberControl = ({member, label, role, onChange, Message, crud}) =>{

    const [loaded, Load] = useState(false)
    const [items , Items] = useState([])

    useEffect(() => {
        Items([])
        let request =  `Setting/Member/${role}`
        //console.log('Members.onLoad',request); alert(request)
        Get(request, Items, Load, Message)
     }, [loaded])   

    return (

        loaded ? 
        <Form.Group>
        <Form.Label style={{ display: 'flex', justifyContent: 'flex-end' }}>{label}</Form.Label>
        <Form.Control as="select" name="MemberId" onChange={onChange} defaultValue={member} readOnly={crud===2} disabled={crud===2}>
            {items.map(item => 
                <option key={item.Id} value={item.Id}>{(item.Name)}</option>
            )}
        </Form.Control>

        </Form.Group>    
        :  
        <div>Members are loading...</div>   
    )
}

export {MemberControl, SpecialInfos}