
const Config = {
    salaam:'Salaam',
    title :'tajwyd',
    logo : 'logo.png',
    time : {
        D: 'Date',
        d: 'Day',
        h : 'Hour',
        m : 'Minute',
        p: 'period'
    }

}

const home = {
    login:'Login',

}

const icons = {
    add : 'https://sites.errais.net/Content/Icons/add.png',
    reload : 'https://sites.errais.net/Content/Icons/reload.png',
    minimize : 'https://sites.errais.net/Content/Icons/minimize.png',
    maximize : 'https://sites.errais.net/Content/Icons/maximize.png'
}

const actions = {
    save:'Save'
}

export default Config
export {home, icons, actions}