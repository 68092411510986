import React, { useState, useEffect } from 'react'
import './Booking.css'
import {Container,Row,Col} from 'react-bootstrap'
import {PlanningDetail} from '../Planning/PlanningDetail'
import {BookingDetail} from './BookingDetail'

export const Booking = ({global}) => {
        const [itemId, setItemId] = useState(0)

        const onSelect = (itemId) => {
                setItemId(itemId)
                //      alert("Booking  "+itemId)
        }

        useEffect(() => {
                //alert('useEffect '+itemId)
        }, [itemId])    


return (

<>
                
{ global.connected() ?
        <Container>
                <Row>
                        <PlanningDetail global={global} onPlan={onSelect}/>
                </Row>  

                <Row>
                        <BookingDetail PlanningId={itemId} />
                </Row>                      
        </Container>
: <h5>Invalid session?</h5> }

</>
        )
}
