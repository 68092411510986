import React,{useState, useEffect} from 'react';
import {Get} from '../Api'

const ColorsControl = ({label = "Colors", onSelect}) => {
    const [loaded, Load] = useState(false)
    const [items, Items]= useState([])

    useEffect(() => {
        Get(`Setting/${label}`, Items, Load)
        //console.log(items); alert(items.length)
     }, [loaded])       

    const OnSelect=(event, item) =>{
        //console.log(item); alert(contextMenu.title)
        onSelect && onSelect(label, item)
    }
 
    return (
        loaded && items.length>0 ? 
    <li class="menu-item" onClick={(e)=>OnSelect(e)}>
        <span class="menu-text">{label}</span>
        <ul class="sub-menu" title={label} >
        {   items.map(item => 
            <li id={`C${item.Id}`} class="menu-item" title={`${item.Back}-${item.Font}`} onClick={(e)=>OnSelect(e,item)}
                style={{backgroundColor:`rgba(${item.Back})`, color:`rgba(${item.Font})`}} value={item.Id}>
             C </li>   
        )}

        </ul>
    </li>
        
        : <div>{label} loading...</div> 
    )
}

const FontsControl = ({label = "Fonts", onSelect}) => {

    const [loaded, Load] = useState(false)
    const [items, Items]= useState([])

    useEffect(() => {
        Get(`Setting/${label}`, Items, Load)
     }, [loaded])       

    const OnSelect=(event, item) =>{
        onSelect && onSelect(label, item)
    }
    
    return (
        loaded && items.length>0 ? 
    <li class="menu-item" onClick={(e)=>OnSelect(e)}>
        <span class="menu-text">{label}</span>
        <ul class="sub-menu sub-menu-font" title={label}>
        {
            items.map(item => 
                <li id={"F"+item.Id} class="menu-item" title={item.Name} onClick={(e)=>OnSelect(e,item)}
                    style={{fontSize:`${item.Size}rem`, fontFamily:`${item.Family}`, fontStyle:`${item.Style}`, fontWeight:`${item.Weight}`}} value={item.Id}>{item.Name}</li>
            
            )
        }

        </ul>
    </li>
        
    : <div>{label} loading...</div> 
    )
}

export {ColorsControl, FontsControl}