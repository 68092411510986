import React, {useState, useEffect} from 'react'
import './Planning.css'
import {PlanControl} from './PlanControl'
import {ToNotify} from '../Shared/ViewControl'
import { toDate, addDays } from '../Shared/Formating'
import {PerformingModal} from '../Performing/PerformingModal'
import {PerformingDetail} from '../Performing/PerformingDetail'
import {PlanModal} from './PlanModal'

const Planning = ({global, role, course, search}) => {
        const entity='Planning'
        const [message, Message] = useState('')
        const [date, setDate] = useState(toDate())
        const [action, Action] = useState({id:0, crud:0, show:false})

        const onClose =() => {
            Action({show:false, id:0, crud:0})
        }
        
        useEffect(() => {
            if(action.id>0) {
                //alert(`Planning.useEffect ${action.id} ${action.show} - ${date}`)
            }
        }, [action.id,date])
        
        const onDate = () => {
            setDate(toDate())
        }

        const onPrev = () => {
            Action({...action,id:0})
            let d=addDays(-7,date)
            setDate(toDate(d))
        }
    
        const onNext =() => {
            Action({...action,id:0})
            let d = addDays(7,date)
            setDate(toDate(d))
        }        

        return (
role>2 ?
<div expand="lg" style={{justifyContent:"center", margin:'0', width:'100%'}}>
<PlanControl
        entity={entity}
        date={date}
        Message={Message}
        onAction={Action}
        onDate={onDate}
        onNext={onNext}
        onPrev={onPrev}
        search={search}
        />

<PerformingDetail
        global={global}
        date={date}
        planning={action.id}
        />

<ToNotify message={message} onClose={()=>Message('')} />

{action.show ?

        <PlanModal
            global={global} // ???
            action={action}
            onHide={onClose}
            Message={Message}
        />

: null}
</div>
: 
<h6>{entity}(s) loading...</h6>
)
    
}

export {Planning}