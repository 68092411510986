import React,{useEffect, useState} from 'react'
import './Notes.css'
import {OnAction} from '../Shared/ActionControl'
import { viewDate } from '../Shared/Formating'
import {Note} from './Notes'
import {Fetch} from '../Api'

const Comments = ({note, search, selected, onSelect, Message}) => {
    const entity='Comment'

    const [items, Items] = useState([]);
    const [loaded, Load] = useState(false);
    
    useEffect(() => {
        if(note>0) {
            Fetch(`${entity}/${note}`, null, 2, Load, Message, Items)
        }
    }, [loaded, note])

    const Create = () => {
        let item={
            NoteId: note,
            Text:'New '
        }
        onFetch(item, 1)
    }   

    const onUpdate=(item) => {
        onFetch(item, 3)
    }

    const onDelete=(item)=> {
        onFetch(item, 4)
    }

    const onFetch = (item,crud) => {
        Fetch(entity, item, crud, Load)
    }

    const onClick = (id) => {     

        onSelect(id)
        //CStyle({backgroundColor:'darkgreen'})        
        //let message= `onSelect (${id}) :  Select ${selected} -> Edit ${editing.Id}`; console.log(message)
    }

        return ( <>

{ loaded ?

<div className="comments-grid">
<OnAction onCreate={()=> Create()} />
    {
        items.filter((item)=>{
            return (item.Text+item.Member+viewDate(item.updatedAt)).indexOf(search)!==-1
        })
        .map(item=>
            <Note key={`comment-${item.Id}`} item={item} selected={selected} 
            onSelect={onClick} onSave={onUpdate} onDelete={onDelete} note={false} fstyle={{backgroundColor:'#606060'}} />
            )
    }
   </div>

:
<h5>{entity}(s) loading...</h5>
}

</>)}

export {Comments}
