import React, {useState, useEffect} from 'react'
import {Form, Button} from 'react-bootstrap'
import {Fetch} from '../Api'


const InOption = ({items, member, planned, defaultValue, onChange, readOnly=false, Message}) => {
    const id=`Eval-${member}-${planned}-${items[0].Parent}` // MeR items[0].Parent 241004 16:21
    const [status, Status] = useState(0)
    const [value, Value] = useState(defaultValue)

    useEffect(()=>{
        const item = items.find(i=>i.Id===value)
        //alert(`Q=${items[0].Parent} A=${value} -> item ${item?.Id}`)
        if(item) {
            const parent = document.getElementById(id)
            parent.style.color = `rgba(${item.Font})`
            parent.style.backgroundColor = `rgba(${item.Back})` // `rgba(${"217,210,233,0.5"})`;
        }    

    },[member, planned, value])

    const onSelect=(e) =>{
        if(e?.target) {
            onChange && onChange(e)
            if(!readOnly && member>0 && e.target.value>0 && value!==e.target.value) {
                Value(e.target.value)
                //  alert(`onSelect ${member},${planned} : ${e.target.name} value=${e.target.value} default=${value}`)
                Fetch('/Evaluation',{member,planned,answer:e.target.value},3,null,Message,null,Status) 
                //alert(`Fetch ${status}`)
                
                const child = document.getElementById(`${id}-${e.target.value}`);
                if(child) {
                    //alert(`${child.id}`) 
                    e.target.style.color = child.style.color
                    e.target.style.backgroundColor = child.style.backgroundColor // `rgba(${"217,210,233,0.5"})`;
                }
                                         
            }
        }
    }
 
    return (

            <Form.Control as="select" id={id} key={id} className="text-right" 
                onChange={e => onSelect(e)} 
                defaultValue={value} 
                readOnly={readOnly} disabled={readOnly} style={{fontSize:'1rem', height:'2rem'}}>
                <option id={`${id}-0`} key={`${id}-0`} value={0}></option>
                {
                    items.map(item => 
                    <option id={`${id}-${item.Id}`} key={`${id}-${item.Id}`} value={item.Id} title={item.Title}
                    selected={value===item.Id} 
                    style={{backgroundColor:`rgba(${item.Back})`, color:`rgba(${item.Font})`}}
                    >{item.Value}</option>
                    )
                }
            </Form.Control> 

        
    )
}

const ToId = ({value, onClick, crud=0, checked}) => {  
    const bstyle = { width: '2rem', display: 'block', padding:'0.2rem', textAlign:'right', border:'none' }  

    const getVariant = () => {
        let bvariant = crud>0 ?  (crud===2 ? "outline-info" : "outline-warning") : "outline-success"
        if(checked) {
            bvariant = bvariant.replace("outline-","")
        }
        return bvariant
    }
    
    return (
<td className='Id' key={value} value={value}>
    <Button key={`Item-${value}-Id`} value={value} variant={getVariant()} style={bstyle}
        onClick={(e) => onClick(e, value)}
    >{value}</Button>      
</td>
 )
}

const InText = ({name,value,label,type,size,onChange,readOnly=false}) => {

    return (
        <Form.Group controlId={`InText-${name}`} className='form-row conter'>
            
            <Form.Control
            className={ (name==='code') ? 'col-sm-9 text-center' : (name==='Phone')?'col-sm-12 text-center':'col-sm-12 text-right' }
            type={type ?? 'text'}
            name={name}                
            placeholder={label ?? name}
            defaultValue={value}
            style={{fontSize:size??'1.5rem', fontWeight:'bold', color:'blue', margin:'1rem 0 1rem 0'}}               
            readOnly={readOnly}
            onChange={onChange}
            //  required
            />
            {/*<Form.Label className='col-sm-2' align='right'>:{label ?? name}</Form.Label>*/}
        </Form.Group>        
    )
}

const InDetail = ({name,value,label,readOnly,size,onChange}) => {

    return (
        <Form.Group controlId={`InDetail-${name}`} className='form-row conter' width='100%'>
            <textarea className="form-control text-right" rows="2" 
                name={name} 
                type="text"
                style={{fontSize:size??'1.2rem', marginTop:'1rem'}}
                defaultValue={value ??''} 
                align='right'
                placeholder={label ?? name}
                readOnly={readOnly??false}  
                onChange={onChange}          
            />
        </Form.Group>
    )
}

/*
In React, components need to be capitalized !
*/
const InCheck = (name, value=false, mode=0, label) => {
    const [checked, setChecked] = React.useState(value)
    return (
        <div className="form-group form-row">
      <label className="control-label col-sm-2" style={{width:"auto", textAlign:"right"}}>
          {label ?? name}: </label>
          <div className="col-sm-1">
            <input type="checkbox" name={name}
            defaultChecked={checked}
            onChange={() => setChecked(!checked)}
            readOnly={(mode??0)===0}
            className="form-control" 
            />        
        </div>
      </div>     
    )
}

export {ToId, InOption, InText, InDetail, InCheck}
