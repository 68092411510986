import React,{useState, useEffect, useLayoutEffect} from 'react'
import './Performing.css'
import '../meTree/meTree.css'

import {PerformingDetail} from './PerformingDetail'
import {PlanningDetail} from '../Planning/PlanningDetail'
import {meSplitter} from '../Shared/meGlobal'
import { Focused } from '../Timer/Timers'
import {ToNotify} from 'fast_inon'

export const Performing = ({global, layout, search, Etat}) => {
    
    const entity='Performing'
    const activity=10

    const [message, Message] = useState('')
    const [itemId, setItemId] = useState(0)
    const [planning, Plan] = useState(0)
    const [perform, Perform] = useState(0)

    const splitter = new meSplitter(`${entity}Side`,`${entity}Container`, layout)

    useLayoutEffect (()=>{  
        splitter.selectorAll() 
            sessionStorage.setItem("routeCurrent","/performing")
            //alert(detail.items.length)      
        },[])

    Focused(1007, activity )

    const onPlan = (id) => {
            Plan(id)
    }

    const onPerform = (id) => {
            Perform(id)
    }

    const onSelect = (event) => {        
        //console.log("Performing.onSelect event.target", event.target); //alert(event.target.value)
        onPlan && onPlan(event.target.value)
        setItemId(event.target.value)        
    }

return (
    global.connected() ?

    <div className="Sides" id={`${entity}Container`}
    onMouseDown={(e)=>{splitter.mouseDown(e)}} 
    onMouseMove={(e)=>{splitter.mouseMove(e)}} 
    onMouseUp={(e)=>{splitter.mouseUp(e)}}
    >

        <div className="side hside" id={`${entity}SidePlanningDetail`} style={{height: '50%'}}>
            <PlanningDetail global={global} onPlan={onSelect} mode={9} />
        </div>
        <div className="side hside" id={`${entity}SidePerformingDetail`} style={{height: '50%'}}>
            <PerformingDetail global={global} onPerform={onPerform} />
        </div>

        <ToNotify 
    message={message}
    onClose={()=>{Message('')}}
/>
    </div>
    : 
    <h1>{global.message}</h1> 
)    
}