import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import '../Managing/Managing.css'
import './Memorizing.css'
import {ToNotify} from '../Shared/ViewControl'
import MemorizeControl from './MemorizeControl'
import {Fetch, Get, Cookie} from '../Api'
import { Focused } from '../Timer/Timers'
import { Duration } from '../Shared/Formating'
import {onGet} from '../Shared/Akademy'
import TreeView from "../meTree/TreeView"
import Activities from '../Shared/Activities'
import {meSplitter} from '../Shared/meGlobal'

const Memorizing = ({search, onEtat, role, course, layout}) => {
    const navigate = useNavigate()
    const onCourse = useCallback(() => navigate(`/course`,{replace:true}), [navigate])

    if(course<2) {
        onCourse()
    }

    const entity='Memorizing'
    const [page, Page] = useState(0)
    const [note, Note] = useState(0)
    const [reported, Reported] = useState(6)
    const [message, Message] = useState('')
    const [loaded, Load] = useState(false)
    const [items , Items] = useState([])

    const [item, Item]= useState({Element:1, Course:course, Focused:29})

    const [selected, Select] = useState(false)
    const [safahaat , Safahaat] = useState([])

    const splitter = new meSplitter(`${entity}Side`,`${entity}Container`,layout)
    useLayoutEffect (()=>{  
        splitter.selectorAll() 
    })

    useEffect(() => {
        //alert(`Course ${course} items ${items.length}`)
        if(items.length===0) {
            Get(entity+"/Safha", Items, Load)
                        
            sessionStorage.setItem("routeCurrent","/memorizing")
        }
        else {
            let current=course>1?course:items[0].Course
            Item({...items[0], Course:current})

            //  console.log('items[0]',items[0],current,safahaat)
            onGet(`/waqafaat/Memorizing&${current}`, Safahaat, Select)
        }    

    }, [loaded])

    useEffect(() => {
        if(safahaat.length>0) { 
            //alert(`Course ${item.Course} Safahaat ${safahaat.length} Element=${item.Element} Safha0=${safahaat[0].Safha} `)

            let i = safahaat.findIndex(s=>s.Safha===item.Element)
            let value = safahaat[0].Safha

            if(i>0) { 
                value = item.Element                
            }
            else {
                let safha = parseInt(Cookie('meSafha') ?? '0')

                if(safha>value && safha<=safahaat[safahaat.length-1].Safha) {
                    value = safha
                    Item({...item, Element:safha})
                }
            }
            //alert(`Course ${item.Course} ${value}`)
            onPage(value)
            onEtat(`${Duration(item.Focused)} - صفحة ${value}`)
        }
    }, [selected])

    const onNote = (id) => {
        Note(id)
    }
    
    const onPrev = () => {
        if(page>safahaat[0].Safha)
        {
            onPage(page-1)
        }
    }

    const onNext =() => {
        if(page<safahaat[safahaat.length-1].Safha)
        {
            onPage(page+1)
        }
    }

    const onNode = (e, levels) => {
        //Page(selected[0])
        if(levels[3]>0 && page!==levels[3]) {
            Page(levels[3])
        }
    }

    const onPage = (safha) => {
        Page(safha)
        document.cookie = `meSafha=${safha}`
        //console.log('Memorizing', safha, page, safahaat); alert(safha)
    }

    useEffect(()=>{
        //alert(`timer ${element} ${activity} ${data.Focused}`)
        Fetch(`Timer`, {
            ActivityId: 4,
            ElementId: page,
            Focused: 1
        }, 1)
    },[page])

    Focused(page, 4, Reported )

return (
                
<>

{ layout && page>0 ?
    <div className="Sides" id={`${entity}Container`} 
        onMouseDown={(e)=>{splitter.mouseDown(e)}} 
        onMouseMove={(e)=>{splitter.mouseMove(e)}} 
        onMouseUp={(e)=>{splitter.mouseUp(e)}}
    >
    <div className="side vside" id={`${entity}SideActivities`} style={{width: '25%'}}>
        <Activities activity={4} element={page} onElement={onNote} reported={reported} search={search} />
    </div>

    <div className="side vside" id={`${entity}SideReadControl`} style={{width: '50%', overflow: 'hidden'}}>
        <MemorizeControl role={role} page={page} onNext={onNext} onPrev={onPrev} onPage={onPage} pages={safahaat} Message={Message} />
    </div>
    <div className="side vside" id={`${entity}SideParts`} style={{width: '25%'}}>
        <TreeView onChange={onNode} page={page} course={item.Course} />
    </div>

    </div>
: 
<MemorizeControl role={role} page={page} onNext={onNext} onPrev={onPrev} onPage={onPage} pages={safahaat} Message={Message} />
}

<ToNotify message={message} onClose={()=>Message('')} />
</>
     )
}

export default Memorizing

/*
    <div className="side vside" id={`${entity}SideTimers`} style={{width: '20%'}}>
        <Timers element={page} onElement={onPage} reported={reported}/>
    </div>

 */