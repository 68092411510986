import React,{useEffect, useState} from 'react'
import {Modal,Row,Col,Form} from 'react-bootstrap'
import {ToNotify} from '../Shared/ViewControl'
import {RoleMembers} from '../Role/RoleMembers'
import {OnSubmit} from '../Shared/ActionControl'
import {InText, InDetail} from '../Shared/InputControl'
import {ModalHeader, ModalFooter} from '../Shared/ModalControl'
import { TeamMembers } from './TeamMembers';
import {Fetch} from '../Api'

export const TeamModal = ({global, action, onHide})  => {

    const entity='Team'
    const title = `${action.crud===1?'إضافة':'تعديل'} مجموعة`
    const readOnly = action.crud===2
    const [loaded, Load] = useState(false)
    const [item, Item] = useState([])
    const [message, Message] = useState('')

    useEffect(() => {
        onLoad()
    }, [loaded])
  
    const onLoad = () => {
        if(action?.show) {
            if(action?.id > 0) {
                global.GetItem(entity, action.id)
                .then((data) =>  {
                        Item(data)
                        Load(true)
                        //console.log('TeamModal.Load', data)
                    }
                )
                .catch(error => {
                    Message(error)   
                })
            }
            else if(action?.crud===1) {
                Item({Id:0, Leader:2, Name:'', Description:''})
                Load(true)
            }
        }
    }    
    
    const onChange = (e) => {
        e.preventDefault()
        Item({...item, [e.target.name]: e.target.value})
    }

    const onFetch = (event) => {
        //console.log('Modal.onFetch', item)
        //alert('Modal.onFetch ' + item.Leader)
        let _item = {
            Id:(action.crud===1)?0:item.Id,
            Name:event.target.Name.value,
            Leader:event.target.Leader.value,
            Description:event.target.Description.value
            }
        Fetch(entity, _item, action.crud)
    }

        return(
            <div className="container">
            <Modal
            show={action.show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalHeader title={title} id={action.id} message={message} />

            <Modal.Body>

 <Row style={{justifyItems:"right"}}>
     <Col sm={11} >
     {
        !(loaded && item) ? <div>{entity} is loading...</div> :    
         <Form onSubmit={onFetch}>

            <InText name="Name" value={item?.Name ?? ''} label={"الأسم"} readOnly={readOnly} size={"2rem"} required onChange={onChange} />

             <RoleMembers role="Leader" entityId={action.id} global={global} id={item.Leader??2}  label='القائدة' readOnly={readOnly} onChange={onChange} Message={Message} />
             <RoleMembers role="Supervisor" entityId={action.id} global={global} id={item.Supervisor??2}  label='المشرفة' readOnly={readOnly} onChange={onChange} Message={Message} />

             <InDetail name="Description" value={item?.Description ?? ''} label={"تقديم"} readOnly={readOnly} onChange={onChange} />

{action.crud===3 ?
    <TeamMembers team={action.id} role={2}/>
:null}

             <OnSubmit crud={action.crud} />
         </Form>
    }
    </Col>
 </Row>

    </Modal.Body>
    <ModalFooter onHide={onHide}/>

    </Modal>

    <ToNotify message={message} onClose={()=>Message('')} />
    
    </div>  
    )
}
